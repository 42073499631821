import axios from 'axios';
import authHeader from './auth-header';

const API_ADMIN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/admin/issue/config/';

class AdminIssueConfigService {

  update(
    like, dislike, neutral, supportIssue, supportProposal, proposalWorstBorderline, rankFunction, supportIssuePeriodFrom, supportIssuePeriodTo,
    supportProposalPeriodFrom, supportProposalPeriodTo, commentProposalPeriodFrom, commentProposalPeriodTo, voteProposalPeriodFrom, voteProposalPeriodTo,
    tags, userId, issueId
  ) {
    return axios.post(API_ADMIN_URL + "update", {
      like,
      dislike,
      neutral,
      supportIssue,
      supportProposal,
      proposalWorstBorderline,
      rankFunction,
      supportIssuePeriodFrom,
      supportIssuePeriodTo,
      supportProposalPeriodFrom,
      supportProposalPeriodTo,
      commentProposalPeriodFrom,
      commentProposalPeriodTo,
      voteProposalPeriodFrom,
      voteProposalPeriodTo,
      tags,
      userId,
      issueId
    },
      { headers: authHeader() }
    );
  }

  getIssue(issue) {
    return axios.get(API_ADMIN_URL, {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getCreator(issue) {
    return axios.get(API_ADMIN_URL + "creator", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  finalizeDeliberation(userId, issueId) {
    return axios.post(API_ADMIN_URL + "finalize-deliberation", {
      userId, issueId
    },
      { headers: authHeader() }
    );
  }

  nextRound(userId, issueId) {
    return axios.post(API_ADMIN_URL + "next-round", {
      userId, issueId
    },
      { headers: authHeader() }
    );
  }

}

export default new AdminIssueConfigService();
