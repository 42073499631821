import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import I18n from '../I18n';
import VerifyVerificationTokenService from "../services/verify.verification.token.service";
import Footer from './footer';
import Header from './header';

export default class VerifyVerificationToken extends Component {
  constructor(props) {
    super(props);
    this.handleVerify = this.handleVerify.bind(this);

    this.state = {
      token: this.props.match.params.verificationToken,
    };
  }

  handleVerify(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      VerifyVerificationTokenService.verify(
        this.state.token
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              <h1 className="govgr-heading-l"><I18n t="main.emailVerification" /></h1>
              <Form
                onSubmit={this.handleVerify}
                ref={c => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="row justify-content-end">
                      <div className="col-md-2">
                        <div className="form-group">
                          <button className="btn btn-warning btn-block"><I18n t="main.verify" /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.message && (
                  <div className="form-group">
                    <div
                      className={
                        this.state.successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {this.state.message}<br />
                      {this.state.successful && (
                        <Route path={this.props.base} render={() =>
                          <Link className="govgr-btn govgr-btn-secondary mt-2" to={"/login"}><I18n t="home.Login" /></Link>
                        } />
                      )}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
