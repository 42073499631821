import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposerIssueMergeService from "../services/proposer.issue.merge.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class ProposerIssueMerge extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeListProposal = this.onChangeListProposal.bind(this);
    this.onDeleteMergeRequest = this.onDeleteMergeRequest.bind(this);

    this.state = {
      userId: AuthService.getCurrentUser().id,
      issueId: this.props.match.params.issueId,
      issue: null,
      adminName: null,
      issueProposals: null,
      myProposal: null,
      proposalId: null,
      proposalsOfOtherProposers: null,
      mergeRequest: null,
      mergeRequestProposers: null,
      listProposals: [],
      successful: false,
      message: ""
    };
  }

  componentDidMount() {
    ProposerIssueMergeService.getIssue(this.state.issueId).then(
      response => {
        this.setState({
          issue: response.data.message
        });
      },
      error => {
        this.setState({
          issue:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerIssueMergeService.getIssueCreator(this.state.issueId).then(
      response => {
        this.setState({
          adminName: response.data.message
        });
      },
      error => {
        this.setState({
          adminName:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerIssueMergeService.getIssueProposals(this.state.issueId).then(
      response => {
        this.setState({
          issueProposals: response.data.message
        });
      },
      error => {
        this.setState({
          issueProposals:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerIssueMergeService.getMergeRequest(this.state.userId, this.state.issueId).then(
      response => {
        this.setState({
          mergeRequest: response.data.message
        });
      },
      error => {
        this.setState({
          mergeRequest:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerIssueMergeService.getMergeRequestProposers(this.state.userId, this.state.issueId).then(
      response => {
        this.setState({
          mergeRequestProposers: response.data.message
        });
      },
      error => {
        this.setState({
          mergeRequestProposers:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  onChangeListProposal(e) {
    // current array of options
    const listProposals = this.state.listProposals
    let index

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      listProposals.push(e.target.value)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = listProposals.indexOf(e.target.value)
      listProposals.splice(index, 1)
    }

    this.setState({
      listProposals: listProposals
    });
  }

  onDeleteMergeRequest() {
    this.setState({
      message: "",
      successful: false
    });

    ProposerIssueMergeService.deleteMergeRequest(
      this.state.mergeRequest[0]._id
    ).then(
      response => {
        this.setState({
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          successful: false,
          message: resMessage
        });
      }
    );
  }

  proposalPerProposer = () => {
    if (this.state.issueProposals) {
      var issueProposals = this.state.issueProposals
      let myIssueProposal = [];
      Object.keys(issueProposals).filter((proposal) => issueProposals[proposal].user.includes(this.state.userId)).map((proposal) =>
        myIssueProposal.push(issueProposals[proposal])
      );
      let issueProposalsOfOtherProposers = [];
      Object.keys(issueProposals).filter((proposal) => !issueProposals[proposal].user.includes(this.state.userId)).map((proposal) =>
        issueProposalsOfOtherProposers.push(issueProposals[proposal])
      );
      if (myIssueProposal[0]._id && !this.state.proposalId) {
        this.setState({
          proposalId: myIssueProposal[0]._id
        });
      }
      return [
        {
          "myProposal": myIssueProposal,
          "proposalsOfOtherProposers": issueProposalsOfOtherProposers,
        }
      ]
    }
  }

  handleUpdate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      ProposerIssueMergeService.update(
        this.state.userId,
        this.state.issueId,
        this.state.proposalId,
        this.state.listProposals
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    const issue = this.state.issue;
    const creator = this.state.adminName;
    const proposalPerProposer = this.proposalPerProposer();
    const myProposal = proposalPerProposer ? proposalPerProposer[0]['myProposal'] : null;
    const proposalsOfOtherProposers = proposalPerProposer ? proposalPerProposer[0]['proposalsOfOtherProposers'] : null;
    const mergeRequest = this.state.mergeRequest ? this.state.mergeRequest.length > 0 ? this.state.mergeRequest : null : null;
    const mergeRequestProposers = this.state.mergeRequestProposers ? this.state.mergeRequestProposers.length > 0 ? this.state.mergeRequestProposers : null : null;
    const mergeRequestProposals = mergeRequest ? mergeRequest[0].mergeRequestProposals : null;
    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/proposer"><I18n t="main.Issues" /></Link>
                } />
              </li>
              <li className="govgr-breadcrumbs__list-item">
                {
                  issue ?
                    Object.keys(issue).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/proposer/issue/" + issue[key]._id}>{issue[key].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
            </ol>
          </div>
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              {
                issue ?
                  Object.keys(issue).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-xl"><I18n t="main.IssueTitle" /></span>
                        <h1 className="govgr-heading-l">{issue[key].title}</h1>
                      </div>;
                    }
                  )
                  :
                  <Loading />
              }
              {
                creator ? (<p className="govgr-hint"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
              }
              {
                myProposal ?
                  Object.keys(myProposal).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-xl"><I18n t="main.MyProposal" /></span>
                        <h1 className="govgr-heading-l">{myProposal[key].title}</h1>
                      </div>;
                    }
                  )
                  :
                  <Loading />
              }
              {!mergeRequestProposers && (<div>
                {!mergeRequest && (<div>
                  <Form
                    onSubmit={this.handleUpdate}
                    ref={c => {
                      this.form = c;
                    }}
                  >
                    {!this.state.successful && (
                      <div>
                        <h1 className="govgr-heading-l"><I18n t="main.Mergemyproposalwith" /></h1>
                        <div>
                          <ul>
                            {
                              proposalsOfOtherProposers ?
                                Object.keys(proposalsOfOtherProposers).length > 0 ?
                                  Object.keys(proposalsOfOtherProposers).map(
                                    key => {
                                      return <div className="form-group" key={key}>
                                        <label>
                                          {proposalsOfOtherProposers[key].title}
                                          <Input
                                            type="radio"
                                            value={proposalsOfOtherProposers[key]._id}
                                            onChange={this.onChangeListProposal}
                                          />
                                        </label>
                                      </div>;
                                    }
                                  )
                                  : <I18n t="main.NoavailableOtherProposals" />
                                : "Loading..."
                            }
                          </ul>
                        </div>

                        <div className="form-group">
                          <button className="btn btn-primary btn-block"><I18n t="main.MergeProposal" /></button>
                        </div>
                      </div>
                    )}

                    {this.state.message && (
                      <div className="form-group">
                        <div
                          className={
                            this.state.successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {this.state.message}
                        </div>
                      </div>
                    )}
                    <CheckButton
                      style={{ display: "none" }}
                      ref={c => {
                        this.checkBtn = c;
                      }}
                    />
                  </Form>
                </div>)}
                {mergeRequest && (<div>
                  <h1 className="govgr-heading-l"><I18n t="main.Detailsofmymergerequest" /></h1>
                  {!this.state.successful && (
                    <div className="card">
                      <div className="card-body">
                        <p className="card-title"><I18n t="main.Mergemyproposalwith" />:</p>
                        <ul>
                          {
                            mergeRequestProposals ?
                              Object.keys(mergeRequestProposals).map(
                                key => {
                                  return <li key={key}>{mergeRequestProposals[key].title}</li>;
                                }
                              )
                              :
                              "Loading..."
                          }
                        </ul>
                      </div>
                      <div className="card-footer">
                        <I18n t="main.Mergerequeststatus" />: <b><I18n t={"main."+mergeRequest[0].status} /></b>, <I18n t="main.Mergerequestaccept" />: <b>{mergeRequest[0].accept}</b>
                        {mergeRequest[0].status === "pending" && (<button className="btn btn-danger float-right" onClick={this.onDeleteMergeRequest}><I18n t="main.DeleteMergerequest" /></button>)}
                      </div>
                    </div>)}

                  {this.state.message && (
                    <div className="form-group">
                      <div
                        className={
                          this.state.successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {this.state.message}
                      </div>
                    </div>
                  )}
                </div>)}
              </div>)}
              {mergeRequestProposers && (<div>
                <div
                  className="alert alert-warning"
                  role="alert"
                >
                  <I18n t="main.Youcancreateamergeproposalwhenanswertoallmergerequests" />
                </div>
              </div>)}
            </main>
          </div>
        </div>
        <Footer /></>
    );
  }
}
