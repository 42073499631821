import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposalCommentService from "../services/proposal.comment.service";
import VoteService from "../services/vote.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';
import RISLEVEL from '../data/risLevel.json';

const required = value => {
  if (!value) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thisfieldisrequired" />
      </p>
    );
  }
};

const vtitle = value => {
  if (value.length < 3) {
    return (
      <div className="alert alert-danger" role="alert">
        <I18n t="main.Thetitlemustbegreaterthan3characters" />
      </div>
    );
  }
};

const trl = {
  '1': 'TRL 1 - Βασικές αρχές παρατηρήθηκαν',
  '2': 'TRL 2 - Διατύπωση της έννοιας της τεχνολογίας',
  '3': 'TRL 3 - Πειραματική απόδειξη της ιδέας',
  '4': 'TRL 4 - Τεχνολογία επικυρωμένη στο εργαστήριο',
  '5': 'TRL 5 - Τεχνολογία επικυρωμένη στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '6': 'TRL 6 - Τεχνολογία που επιδεικνύεται στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '7': 'TRL 7 - Επίδειξη πρωτοτύπου συστήματος σε επιχειρησιακό περιβάλλον',
  '8': 'TRL 8 - Το σύστημα ολοκληρώθηκε και πιστοποιήθηκε',
  '9': 'TRL 9 - Πραγματικό σύστημα αποδεδειγμένο σε επιχειρησιακό περιβάλλον (ανταγωνιστική κατασκευή στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής· ή στο διάστημα)',
};

export default class ProposalCommentCreate extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.handleCreate = this.handleCreate.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeSupportingMaterial = this.onChangeSupportingMaterial.bind(this);
    this.onChangeShowComment = this.onChangeShowComment.bind(this);
    this.handleVote = this.handleVote.bind(this);

    this.state = {
      title: "",
      supportingMaterial: "",
      showComment: true,
      userId: AuthService.getCurrentUser().id,
      proposalId: this.props.match.params.proposalId,
      proposal: null,
      proposerName: null,
      proposalComments: null,
      issueVoteLimits: null,
      issueMyVoteLikes: null,
      issueMyVoteDislikes: null,
      issueMyVoteNeutrals: null,
      vote: null,
      successful: false,
      message: ""
    };
  }



  componentDidMount() {
    ProposalCommentService.getProposal(this.state.proposalId).then(
      response => {
        this.setState({
          proposal: response.data.message
        });
      },
      error => {
        this.setState({
          proposal:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposalCommentService.getProposalProposer(this.state.proposalId).then(
      response => {
        this.setState({
          proposerName: response.data.message
        });
      },
      error => {
        this.setState({
          proposerName:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposalCommentService.getProposalComments(this.state.proposalId).then(
      response => {
        this.setState({
          proposalComments: response.data.message
        });
      },
      error => {
        this.setState({
          proposalComments:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    VoteService.getIssueVoteLimits(this.state.userId, this.state.proposalId).then(
      response => {
        this.setState({
          issueVoteLimits: response.data.message
        });
      },
      error => {
        this.setState({
          issueVoteLimits:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    VoteService.getIssueMyVoteLikes(this.state.userId, this.state.proposalId).then(
      response => {
        this.setState({
          issueMyVoteLikes: response.data.message
        });
      },
      error => {
        this.setState({
          issueMyVoteLikes:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    VoteService.getIssueMyVoteDislikes(this.state.userId, this.state.proposalId).then(
      response => {
        this.setState({
          issueMyVoteDislikes: response.data.message
        });
      },
      error => {
        this.setState({
          issueMyVoteDislikes:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    VoteService.getIssueMyVoteNeutrals(this.state.userId, this.state.proposalId).then(
      response => {
        this.setState({
          issueMyVoteNeutrals: response.data.message
        });
      },
      error => {
        this.setState({
          issueMyVoteNeutrals:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    VoteService.getMyVoteOnProposal(this.state.userId, this.state.proposalId).then(
      response => {
        this.setState({
          vote: response.data.message
        });
      },
      error => {
        this.setState({
          vote:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value
    });
  }

  onChangeSupportingMaterial(e) {
    this.setState({
      supportingMaterial: e.target.value
    });
  }

  onChangeShowComment(e) {
    this.setState({
      showComment: e.target.checked
    });
  }

  handleCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      ProposalCommentService.create(
        this.state.title,
        this.state.supportingMaterial,
        this.state.showComment,
        this.state.userId,
        this.state.proposalId
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  handleVote(e, checkedVote) {
    e.preventDefault();
    // this.setState({
    //   message: "",
    //   successful: false
    // });
    if (checkedVote) {
      const attributes = checkedVote.split('_');
      VoteService.vote(
        attributes[1],
        attributes[0],
        this.state.userId
      ).then(
        response => {
          // this.setState({
          //   message: response.data.message,
          //   successful: true
          // });
          const alreadyVote = this.state.vote;
          if (attributes[1] === '1') {
            if (alreadyVote && alreadyVote.split('_')[1] === '2') {
              this.setState({
                issueMyVoteDislikes: this.state.issueMyVoteDislikes - 1
              });
            }
            if (alreadyVote && alreadyVote.split('_')[1] === '3') {
              this.setState({
                issueMyVoteNeutrals: this.state.issueMyVoteNeutrals - 1
              });
            }
            this.setState({
              issueMyVoteLikes: this.state.issueMyVoteLikes + 1
            });
          }
          if (attributes[1] === '2') {
            if (alreadyVote && alreadyVote.split('_')[1] === '1') {
              this.setState({
                issueMyVoteLikes: this.state.issueMyVoteLikes - 1
              });
            }
            if (alreadyVote && alreadyVote.split('_')[1] === '3') {
              this.setState({
                issueMyVoteNeutrals: this.state.issueMyVoteNeutrals - 1
              });
            }
            this.setState({
              issueMyVoteDislikes: this.state.issueMyVoteDislikes + 1
            });
          }
          if (attributes[1] === '3') {
            if (alreadyVote && alreadyVote.split('_')[1] === '1') {
              this.setState({
                issueMyVoteLikes: this.state.issueMyVoteLikes - 1
              });
            }
            if (alreadyVote && alreadyVote.split('_')[1] === '2') {
              this.setState({
                issueMyVoteDislikes: this.state.issueMyVoteDislikes - 1
              });
            }
            this.setState({
              issueMyVoteNeutrals: this.state.issueMyVoteNeutrals + 1
            });
          }
          this.setState({
            vote: attributes[0] + '_' + attributes[1],
            message: ""
          });
          this.props.history.push("/"+ (this.props.location.pathname.substring(1, 3) === 'pr' ? 'gr' : this.props.location.pathname.substring(1, 3) ) + "/issue/" + this.state.proposal[0].issue[0]._id);
          //window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    } else {
      const attributes = this.state.vote.split('_');
      VoteService.voteDelete(
        attributes[1],
        attributes[0],
        this.state.userId
      ).then(
        response => {
          // this.setState({
          //   message: response.data.message,
          //   successful: true
          // });
          if (attributes[1] === '1') {
            this.setState({
              issueMyVoteLikes: this.state.issueMyVoteLikes - 1
            });
          }
          if (attributes[1] === '2') {
            this.setState({
              issueMyVoteDislikes: this.state.issueMyVoteDislikes - 1
            });
          }
          if (attributes[1] === '3') {
            this.setState({
              issueMyVoteNeutrals: this.state.issueMyVoteNeutrals - 1
            });
          }
          this.setState({
            vote: null
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    //     const [alignment, setAlignment] = React.useState('left');
    //
    // const handleAlignment = (event, newAlignment) => {
    //   setAlignment(newAlignment);
    // };
    const proposal = this.state.proposal;
    const proposer = this.state.proposerName;
    const proposalComments = this.state.proposalComments;
    const issueVoteLimits = this.state.issueVoteLimits;
    const issueMyVoteLikes = this.state.issueMyVoteLikes;
    const issueMyVoteDislikes = this.state.issueMyVoteDislikes;
    const issueMyVoteNeutrals = this.state.issueMyVoteNeutrals;
    Moment.suppressDeprecationWarnings = true;
    const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
    const minC = proposal ? Moment(proposal[0].issue[0].commentProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
    const maxC = proposal ? Moment(proposal[0].issue[0].commentProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
    const minV = proposal ? Moment(proposal[0].issue[0].voteProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
    const maxV = proposal ? Moment(proposal[0].issue[0].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
    return (
      <>
        <Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                {
                  proposal ?
                    Object.keys(proposal).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/issue/" + proposal[key].issue[0]._id}>{proposal[key].issue[0].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
            </ol>
          </div>
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              {
                proposal ?
                  Object.keys(proposal).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-xl"><I18n t="main.ProposalTitleandDescription" /></span>
                        <h1 className="govgr-heading-l">{proposal[key].title}</h1>
                        {
                          proposal[key].amountOfFundingRequested ?
                            <>
                              <dl className="govgr-summary-list">
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.description" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].description}</dd>
                                </div>
                                {!proposal[key].newRisLevel && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelOne" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelOne}
                                        {proposal[key].risLevelOne !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelOne]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelTwo" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelTwo}
                                        {proposal[key].risLevelTwo !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelTwo]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelThree" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelThree}
                                        {proposal[key].risLevelThree !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelThree]}</p>)}
                                      </dd>
                                    </div>
                                  </>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.newRisLevel" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].newRisLevel ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {proposal[key].newRisLevel && (
                                  <div className="govgr-summary-list__row">
                                    <dt className="govgr-summary-list__key"><I18n t="main.newRisLevelDescription" /></dt>
                                    <dd className="govgr-summary-list__value">{proposal[key].newRisLevelDescription}</dd>
                                  </div>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.amountOfFundingRequested" /></dt>
                                  <dd className="govgr-summary-list__value">{parseInt(proposal[key].amountOfFundingRequested).toLocaleString('el-GR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.duration" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].duration}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityStage" /></dt>
                                  <dd className="govgr-summary-list__value">{trl[proposal[key].trl]}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityDocumentation" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].maturityStage}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.risDescription" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].risDescription}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerOne" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerOne}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerTwo" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerTwo}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerThree" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerThree}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerFour" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerFour}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartner" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartner ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {proposal[key].searchPartner && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.searchPartnersAndProfiles" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].searchPartnersAndProfiles}</dd>
                                    </div>
                                  </>
                                )}
                              </dl>
                            </>
                          :
                          ''
                        }
                      </div>;
                    }
                  )
                  :
                  <Loading />
              }
              {
                proposal ?
                  Object.keys(proposal).map(
                    key => {
                      return <div key={key}><p className="govgr-hint"><I18n t="main.IssueTitle" />: {proposal[key].issue[0].title}</p></div>;
                    }
                  )
                  :
                  <Loading />
              }
              {
                proposer ? (<p className="govgr-hint"><I18n t="main.Proposer" />: {proposer}</p>) : <Loading />
              }
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-tab1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true"><I18n t="main.ProposalComments" /></button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-tab1">
                  <div>
                    {
                      proposalComments ?
                        Object.keys(proposalComments).length > 0 ?
                          Object.keys(proposalComments).filter(item => {
                            const citizenUserId = this.state.userId;
                            return (
                              proposalComments[item].user.indexOf(citizenUserId) > -1 || proposalComments[item].showComment === true
                            );
                          })
                            .map(
                              key => {
                                let supportingMaterial = proposalComments[key].supportingMaterial;
                                let supportingMaterialInfo = '';
                                if (supportingMaterial) {
                                  const material = supportingMaterial.split(',');
                                  supportingMaterialInfo = material.map(item => {
                                    const url = item.trim().startsWith('https') ? item.trim() : item.trim().startsWith('http') ? item.trim() : 'http://' + item.trim();
                                      return '<a href="'+url+'" target="_blank" rel="noopener noreferrer">' + url + '</a>'
                                    }).join(', ');
                                }
                                return (
                                  <div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
                                    <div className="card-body">
                                      <div className="row">
                                        <div className="col col-md-10">
                                          <h5 className="card-title">
                                            {proposalComments[key].title}
                                          </h5>
                                          {supportingMaterial ? <I18n t="main.SupportingMaterial" /> : ""}
                                          {supportingMaterial ? <span dangerouslySetInnerHTML={{ __html: ': ' + supportingMaterialInfo }} /> : ""}
                                        </div>
                                        {
                                          proposalComments[key].forVerification && proposalComments[key].user[0] === this.state.userId ?
                                            (
                                              <div className="col col-md-2 border-start">
                                                <p className="card-text">
                                                  <Route path={this.props.base} render={() =>
                                                    <Link to={"/comment_id/" + proposalComments[key]._id}><I18n t="main.Check" />!</Link>
                                                  } />
                                                </p>
                                              </div>
                                            )
                                            : ""
                                        }
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          : <ul className="timeline mt-4"><I18n t="main.NoavailableProposalComments" /></ul>
                        : <Loading />
                    }
                  </div>
                  {Moment().isBetween(minC, maxC, undefined, '[]') && (<div><hr /><h3><I18n t="main.AddComment" /></h3>
                    <hr />
                  </div>)}
                  <Form
                    onSubmit={this.handleCreate}
                    ref={c => {
                      this.form = c;
                    }}
                  >
                    {!this.state.successful && Moment().isBetween(minC, maxC, undefined, '[]') && (
                      <div>
                        <div className="govgr-field">
                          <p className="govgr-hint"><I18n t="main.Comment" /></p>
                          <Textarea
                            className="govgr-textarea"
                            rows="5"
                            name="title"
                            value={this.state.title}
                            onChange={this.onChangeTitle}
                            validations={[required, vtitle]}
                          />
                        </div>
                        <div className="govgr-field">
                          <p className="govgr-hint"><I18n t="main.Supportingmaterialurlsdividedbycomma" /></p>
                          <Input
                            type="text"
                            className="govgr-input"
                            name="supportingMaterial"
                            value={this.state.supportingMaterial}
                            onChange={this.onChangeSupportingMaterial}
                          />
                        </div>
                        <p className="text-danger"><I18n t="main.Supportingmaterialurlsdividedbycomma" /></p>
                        <div className="govgr-checkboxes">
                          <div className="govgr-checkboxes__item">
                            <label className="govgr-label govgr-checkboxes__label">
                              <I18n t="main.Showcommentonothercitizens" />
                              <Input
                                className="govgr-checkboxes__input"
                                type="checkbox"
                                name="showComment"
                                checked={this.state.showComment}
                                onChange={this.onChangeShowComment}
                              />
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <button className="btn btn-primary btn-block"><I18n t="main.CreateComment" /></button>
                        </div>
                      </div>
                    )}

                    {this.state.message && (
                      <div className="form-group">
                        <div
                          className={
                            this.state.successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {this.state.message}
                        </div>
                      </div>
                    )}
                    <CheckButton
                      style={{ display: "none" }}
                      ref={c => {
                        this.checkBtn = c;
                      }}
                    />
                  </Form>
                </div>
              </div>
            </main>
            <aside className="govgr-grid-column-one-third">
              {Moment().isBetween(minV, maxV, undefined, '[]') && (<div><h3><I18n t="main.VoteAnalysis" /></h3>
                <div className="alert alert-info">
                  <p> <I18n t="main.VotesperIssue" /> (<I18n t="main.MyVotes" /> / <I18n t="main.issueLimits" />) </p>
                  <p><span className="badge badge-success">
                    <I18n t="main.Likes" />: {issueMyVoteLikes !== null ? issueMyVoteLikes === 0 ? "0" : issueMyVoteLikes : "Loading..."} / {issueVoteLimits ? issueVoteLimits.issue_max_likes : "Loading..."}
                  </span></p>
                  <p><span className="badge badge-danger">
                    <I18n t="main.Dislikes" />: {issueMyVoteDislikes !== null ? issueMyVoteDislikes === 0 ? "0" : issueMyVoteDislikes : "Loading..."} / {issueVoteLimits ? issueVoteLimits.issue_max_dislikes : "Loading..."}
                  </span></p>
                  <p><span className="badge badge-secondary">
                    <I18n t="main.Neutral" />: {issueMyVoteNeutrals !== null ? issueMyVoteNeutrals === 0 ? "0" : issueMyVoteNeutrals : "Loading..."} / {issueVoteLimits ? issueVoteLimits.issue_max_neutral : "Loading..."}
                  </span></p>
                </div>
                <div>
                  {
                    proposal ? Object.keys(proposal).map(
                      key => {
                        return <div key={key} className="card text-center"><h3><I18n t="main.VoteHere" /></h3>
                          <div className="card-body">
                            <ToggleButtonGroup
                              value={this.state.vote}
                              exclusive
                              onChange={this.handleVote}
                              aria-label={<I18n t="main.Vote" />}
                            >
                              <ToggleButton value={proposal[key]._id + "_1"}>
                                <I18n t="main.Like" />
                              </ToggleButton>
                              <ToggleButton value={proposal[key]._id + "_2"}>
                                <I18n t="main.Dislike" />
                              </ToggleButton>
                              <ToggleButton value={proposal[key]._id + "_3"}>
                                <I18n t="main.Neutral" />
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </div>
                        </div>;
                      }
                    ) : <Loading />
                  }
                </div>
              </div>)}
            </aside>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
