import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AdminProposalCommentService from "../services/admin.proposal.comment.service";
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';
import RISLEVEL from '../data/risLevel.json';

const trl = {
  '1': 'TRL 1 - Βασικές αρχές παρατηρήθηκαν',
  '2': 'TRL 2 - Διατύπωση της έννοιας της τεχνολογίας',
  '3': 'TRL 3 - Πειραματική απόδειξη της ιδέας',
  '4': 'TRL 4 - Τεχνολογία επικυρωμένη στο εργαστήριο',
  '5': 'TRL 5 - Τεχνολογία επικυρωμένη στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '6': 'TRL 6 - Τεχνολογία που επιδεικνύεται στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '7': 'TRL 7 - Επίδειξη πρωτοτύπου συστήματος σε επιχειρησιακό περιβάλλον',
  '8': 'TRL 8 - Το σύστημα ολοκληρώθηκε και πιστοποιήθηκε',
  '9': 'TRL 9 - Πραγματικό σύστημα αποδεδειγμένο σε επιχειρησιακό περιβάλλον (ανταγωνιστική κατασκευή στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής· ή στο διάστημα)',
};

export default class AdminProposalComment extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }

    this.state = {
      userId: AuthService.getCurrentUser().id,
      proposalId: this.props.match.params.proposalId,
      proposal: null,
      proposerName: null,
      proposalComments: null,
      proposalHistory: null,
    };
  }

  componentDidMount() {
    AdminProposalCommentService.getProposal(this.state.proposalId).then(
      response => {
        this.setState({
          proposal: response.data.message
        });
      },
      error => {
        this.setState({
          proposal:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminProposalCommentService.getProposalProposer(this.state.proposalId).then(
      response => {
        this.setState({
          proposerName: response.data.message
        });
      },
      error => {
        this.setState({
          proposerName:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminProposalCommentService.getProposalComments(this.state.proposalId).then(
      response => {
        this.setState({
          proposalComments: response.data.message
        });
      },
      error => {
        this.setState({
          proposalComments:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminProposalCommentService.getProposalHistory(this.state.proposalId).then(
      response => {
        this.setState({
          proposalHistory: response.data.message
        });
      },
      error => {
        this.setState({
          proposalHistory:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    const proposal = this.state.proposal;
    const proposer = this.state.proposerName;
    const proposalComments = this.state.proposalComments;
    const proposalHistory = this.state.proposalHistory;
    Moment.locale('el');
    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/admin"><I18n t="main.Issues" /></Link>
                } />
              </li>
              <li className="govgr-breadcrumbs__list-item">
                {
                  proposal ?
                    Object.keys(proposal).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/admin/issue/" + proposal[key].issue[0]._id}>{proposal[key].issue[0].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
            </ol>
          </div>
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              {
                proposal ?
                  Object.keys(proposal).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-xl"><I18n t="main.ProposalTitleandDescription" /></span>
                        <h1 className="govgr-heading-l">{proposal[key].title}</h1>
                        {
                          proposal[key].amountOfFundingRequested ?
                            <>
                              <dl className="govgr-summary-list">
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.description" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].description}</dd>
                                </div>
                                {!proposal[key].newRisLevel && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelOne" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelOne}
                                        {proposal[key].risLevelOne !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelOne]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelTwo" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelTwo}
                                        {proposal[key].risLevelTwo !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelTwo]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelThree" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelThree}
                                        {proposal[key].risLevelThree !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelThree]}</p>)}
                                      </dd>
                                    </div>
                                  </>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.newRisLevel" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].newRisLevel ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {proposal[key].newRisLevel && (
                                  <div className="govgr-summary-list__row">
                                    <dt className="govgr-summary-list__key"><I18n t="main.newRisLevelDescription" /></dt>
                                    <dd className="govgr-summary-list__value">{proposal[key].newRisLevelDescription}</dd>
                                  </div>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.amountOfFundingRequested" /></dt>
                                  <dd className="govgr-summary-list__value">{parseInt(proposal[key].amountOfFundingRequested).toLocaleString('el-GR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.duration" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].duration}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityStage" /></dt>
                                  <dd className="govgr-summary-list__value">{trl[proposal[key].trl]}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityDocumentation" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].maturityStage}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.risDescription" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].risDescription}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerOne" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerOne}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerTwo" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerTwo}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerThree" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerThree}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerFour" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerFour}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartner" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartner ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {proposal[key].searchPartner && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.searchPartnersAndProfiles" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].searchPartnersAndProfiles}</dd>
                                    </div>
                                  </>
                                )}
                              </dl>
                            </>
                          :
                          ''
                        }
                      </div>;
                    }
                  )
                  :
                  (
                    <div className="govgr-warning-text">
                      <span className="govgr-warning-text__icon">!</span>
                      <strong className="govgr-warning-text__text">
                        <span className="govgr-warning-text__assistive">Loading</span>
                        Loading
                      </strong>
                    </div>
                  )
              }
              {
                proposer ? (<p className="govgr-body"><I18n t="main.Proposer" />: {proposer}</p>) : <Loading />
              }
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-tab1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true"><I18n t="main.ProposalComments" /></button>
                  <button className="nav-link" id="nav-tab2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false"><I18n t="main.ProposalHistory" /></button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-tab1">
                  <div>
                    {
                      proposalComments ?
                        Object.keys(proposalComments).length > 0 ?
                          Object.keys(proposalComments).map(
                            key => {
                              let supportingMaterial = proposalComments[key].supportingMaterial;
                              let supportingMaterialInfo = '';
                              if (supportingMaterial) {
                                const material = supportingMaterial.split(',');
                                supportingMaterialInfo = material.map(item => {
                                  const url = item.trim().startsWith('https') ? item.trim() : item.trim().startsWith('http') ? item.trim() : 'http://' + item.trim();
                                    return '<a href="'+url+'" target="_blank" rel="noopener noreferrer">' + url + '</a>'
                                  }).join(', ');
                              }
                              return (
                                <div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col col-md-9">
                                        <h5 className="card-title">
                                          {proposalComments[key].title}
                                        </h5>
                                        {supportingMaterial ? <I18n t="main.SupportingMaterial" /> : ""}
                                        {supportingMaterial ? <span dangerouslySetInnerHTML={{ __html: ': ' + supportingMaterialInfo }} /> : ""}
                                      </div>
                                      <div className="col col-md-3 border-start">
                                        <p className="card-text">
                                          {proposalComments[key].showComment ? <I18n t="main.Showtoothercitizens" /> : <I18n t="main.NotShowtoothercitizens" />}
                                        </p>
                                        <p className="card-text">
                                          <I18n t="main.User" />: <b>{proposalComments[key].user[0].username}</b>, {proposalComments[key].user[0].email}
                                        </p>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              );
                            }
                          )
                          : <ul className="timeline mt-4"><I18n t="main.NoavailableProposalComments" /></ul>
                        : <ul className="timeline mt-4">Loading...</ul>
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="nav-tab2">
                  <ul className="timeline mt-4">
                    {
                      proposalHistory ?
                        Object.keys(proposalHistory).length > 0 ?
                          Object.keys(proposalHistory).map(
                            key => {
                              var dt = new Date(proposalHistory[key].createdAt).toISOString().replace(/T/, ' ').replace(/\..+/, '');
                              return <li key={key} className="event" data-date={Moment(dt).format('DD/MM/yyyy HH:mm:ss')}>
                                <h3>{proposalHistory[key].title}</h3>
                                <p><I18n t="main.PreviousTitle" />: {proposalHistory[key].oldTitle}</p>
                                {
                                  proposalHistory[key].proposalComments ? Object.keys(proposalHistory[key].proposalComments).map(
                                    keys => {
                                      return <p key={keys}><I18n t="main.Comment" />: {proposalHistory[key].proposalComments[keys].title}</p>;
                                    }
                                  ) : "Loading..."
                                }
                              </li>;
                            }
                          )
                          : <I18n t="main.NoavailableProposalHistory" />
                        : "Loading..."
                    }
                  </ul>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
