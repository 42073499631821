import axios from 'axios';
import authHeader from './auth-header';

const API_CITIZEN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/citizen/proposal/';

class ProposalCommentService {

  create(title, supportingMaterial, showComment, userId, proposalId) {
    return axios.post(API_CITIZEN_URL + "comment/create", {
      title,
      supportingMaterial,
      showComment,
      userId,
      proposalId
    },
      { headers: authHeader() }
    );
  }

  getProposal(proposal) {
    return axios.get(API_CITIZEN_URL, {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalProposer(proposal) {
    return axios.get(API_CITIZEN_URL + "proposer", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalComments(proposal) {
    return axios.get(API_CITIZEN_URL + "comments", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

}

export default new ProposalCommentService();
