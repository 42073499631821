import axios from 'axios';
import authHeader from './auth-header';

class VerifyVerificationTokenService {

  verify(token) {
    return axios.get(process.env.REACT_APP_BACKEND_URL + "/verify/" + token, {
      headers: authHeader(),
    }
    );
  }

}

export default new VerifyVerificationTokenService();
