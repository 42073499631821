import { createI18n } from 'react-router-i18n';

// Array of supported locales
// The first in the array is treated as the default locale
const locales = ['gr', 'en'];

// Dictionary of translations
const translations = {
  gr: {
    main: {
      accepted: 'Εγκρίθηκε',
      AcceptMergerequest: 'Αποδοχή αιτήματος συγχώνευσης',
      Actions: 'Ενέργειες',
      AddComment: 'Προσθήκη Σχολίου',
      Address: 'Διεύθυνση',
      Afm: 'ΑΦΜ',
      amountOfFundingRequested: 'Ύψος Χρηματοδότησης που αιτείται',
      amountOfFundingRequestedHint: 'π.χ. 10000',
      and: 'και',
      andcurrentRoundis: 'και ο τρέχων Γύρος είναι',
      Back: 'Πίσω',
      business: 'Επιχείρηση',
      Changedescriptionofissue: 'Αλλαγή περιγραφής του θέματος',
      ChangeTitleandDescriptionofProposal: 'Αλλαγή τίτλου και περιγραφής πρότασης',
      Changetitleofissue: 'Αλλαγή τίτλου θέματος',
      Check: 'Έλεγχος',
      City: 'Πόλη',
      closed: 'κλειστό',
      ClosedIssues: 'Κλειστά Θέματα',
      Comment: 'Σχόλιο',
      CommentInHistoryWithVerification: ' Σχόλιο στο ιστορικό με επαλήθευση: ',
      Commentproposalperiodfromdate: 'Περίοδος σχολιασμού πρότασης απο την ημερομηνία',
      Commentproposalperiodfromtime: 'Περίοδος σχολιασμού πρότασης απο την ώρα',
      Commentproposalperiodtodate: 'Περίοδος σχολιασμού πρότασης μέχρι την ημερομηνία',
      Commentproposalperiodtotime: 'Περίοδος σχολιασμού πρότασης μέχρι την ώρα',
      CommentsInHistory: ' Ιστορικό Σχολίων: ',
      Config: 'Διαμόρφωση',
      countProposalsPerDay: 'Αρ. προτάσεων ανά ημέρα',
      countProposalsPerThematikiEnotita: 'Αρ. προτάσεων ανά θεματική ενότητα',
      CreateComment: 'Δημιουργία σχολίου',
      CreateIssue: 'Δημιουργία Θέματος',
      Createnewissue: 'Δημιουργία νέου θέματος',
      CreateNextRound: 'Δημιουργία Επόμενου Γύρου',
      CreateProposal: 'Δημιουργία Πρότασης',
      Creator: 'Δημιουργός',
      CurrentRound: 'Τρέχων Γύρος',
      CurrentTitle: 'Τρέχων Τίτλος',
      deleted: 'Διαγράφηκε',
      DeleteMergerequest: 'Διαγραφή αιτήματος συγχώνευσης',
      description: 'Περιγραφή',
      Detailsofmymergerequest: 'Λεπτομέρειες του αιτήματός μου συγχώνευσης',
      Detailsofmyproposalmergerequests: 'Λεπτομέρειες των αιτημάτων συγχώνευσης της πρότασής μου',
      Detailsofmyproposalmergerequestshistory: 'Λεπτομέρειες του ιστορικού αιτημάτων συγχώνευσης της πρότασής μου',
      DetailsofproposalcreatorProposalORmergeRequestProposalsmergerequestshistory: 'Λεπτομέρειες του ιστορικού αιτημάτων συγχώνευσης προτάσεων (Πρόταση δημιουργού Ή Αίτημα συγχώνευσης προτάσεων)',
      Dislike: 'Αρνητικό',
      Dislikes: 'Αρνητικά',
      DonotVerify: 'Δεν επαληθεύω',
      duration: 'Χρονική διάρκεια σε μήνες',
      Edit: 'Επεξεργασία',
      EditIssue: 'Επεξεργασία Θέματος',
      EditProposal: 'Επεξεργασία Πρότασης',
      eg: 'π.χ',
      eg3lastrankedproposalnotcontinuetootherphases: 'π.χ Οι 3 τελευταίες προτάσεις στην κατάταξη δεν συνεχίζουν στην επόμενη φάση',
      emailVerification: 'Επιβεβαίωση E-mail',
      exportProposalsToExcel: 'Εξαγωγή προτάσεων σε Excel',
      FinalActiveProposals: 'Τελικές Επικρατέστερες Προτάσεις',
      FinalizeDeliberation: 'Ολοκλήρωση της Διαβούλευσης',
      FinalParticipants: 'Σύνολο Τελικών Συμμετεχόντων',
      fordislike: 'για αρνητικό',
      forlike: 'για θετικό',
      forneutral: 'για ουδέτερο',
      Frommergerequestcreator: 'από τον δημιουργό αιτήματος συγχώνευσης',
      FutureIssues: 'Μελλοντικά Θέματα',
      HandleDeliberation: 'Διαχείριση Διαβούλευσης',
      helpInfo: 'Οδηγίες χρήσης εφαρμογής',
      Inordertosubmityourownproposalpress: 'Προκειμένου να εισάγετε τη δική σας πρόταση πατήστε',
      instituteResearcher: 'Ερευνητής Ινστιτούτου',
      Issue: 'Θέμα',
      IssueConfig: 'Διαμόρφωση Θέματος',
      IssueDescription: 'Περιγραφή Θέματος',
      IssueHistory: 'Ιστορικό Θέματος',
      IssueInfo: 'Πληροφορίες Θέματος',
      issueLimits: 'Μέγιστο Πλήθος ψήφων',
      IssueProposals: 'Προτάσεις Θεμάτων',
      IssueResults: 'Αποτελέσματα Θέματος',
      Issues: 'Θέματα',
      Issuesfordeliberation: 'Θέματα προς Διαβούλευση',
      IssueTitle: 'Τίτλος Θέματος',
      JobTitle: 'Επωνυμία (οι ερευνητές βάζουν Ινστιτούτο ή εργαστήριο και οι Καθηγητές τμήμα/Πανεπιστήμιο)',
      Like: 'Θετικό',
      Likes: 'Θετικά',
      maturityDocumentation: 'Τεκμηρίωση ωριμότητας',
      maturityStage: 'Στάδιο ωριμότητας',
      MaxDislikes: 'Μέγιστο Πλήθος Αρνητικών ψήφων',
      MaxLikes: 'Μέγιστο Πλήθος Θετικών ψήφων',
      MaxNeutral: 'Μέγιστο Πλήθος Ουδέτερων ψήφων',
      Mergemyproposalwith: 'Συγχώνευση της πρότασής μου με',
      MergeProposal: 'Συγχώνευση Πρότασης',
      MergeProposals: 'Συγχώνευση Προτάσεων',
      Mergerequestaccept: 'Αποδοχή αιτήματος συγχώνευσης',
      mergerequestProposalswithProposers: 'Αίτημα συγχώνευσης πρότασης με τους Δημιουργούς προτάσεων ',
      MergeRequests: 'Αιτήματα συγχώνευσης',
      MergeRequestsHistory: 'Ιστορικό αιτημάτων συγχώνευσης',
      Mergerequeststatus: 'Κατάσταση αιτήματος συγχώνευσης',
      Minissuesupports: 'Ελάχιστο πλήθος υποστήριξης Θέματος',
      Minproposalsupports: 'Ελάχιστο πλήθος υποστήριξης Πρότασης',
      MyProposal: 'Η πρότασή μου',
      MyVotes: 'Οι ψήφοι μου',
      Neutral: 'Ουδέτερο',
      newRisLevel: 'Σε περίπτωση που δεν περιλαμβάνεται ο Κωδικός Επιπέδου 3 που επιθυμείτε στο EXCEL μπορείτε να καταθέσετε το δικό σας',
      newRisLevelDescription: 'Περιγραφή Νέου Κωδικού',
      NoavailableClosedIssues: 'Δεν υπάρχουν διαθέσιμα Κλειστά Θέματα',
      NoavailableFuturesIssues: 'Δεν υπάρχουν διαθέσιμα Μελλοντικά Θέματα',
      NoavailableIssueProposals: 'Δεν υπάρχουν διαθέσιμες Προτάσεις Θέματος',
      NoavailableOtherProposals: 'Δεν υπάρχουν διαθέσιμες Άλλες Προτάσεις',
      NoavailableProposalComments: 'Δεν υπάρχουν διαθέσιμα σχόλια για την πρόταση',
      NoavailableProposalHistory: 'Δεν υπάρχει διαθέσιμο Ιστορικό Προτάσεων',
      NoavailableProposalswithmyComments: 'Δεν υπάρχουν διαθέσιμες προτάσεις με σχόλιά μου',
      NoavailableProposalswithmySupports: 'Δεν υπάρχουν διαθέσιμες προτάσεις με υποστήριξή μου',
      NoavailableRunningIssues: 'Δεν υπάρχουν διαθέσιμα Ενεργά Θέματα',
      NotShowtoothercitizens: 'Να μην εμφανίζεται σε άλλους πολίτες',
      Occupation: 'Ένασχόληση',
      open: 'Ανοιχτό',
      OpenFinalizedDeliberation: 'Άνοιγμα Οριστικοποιημένης Διαβούλευσης',
      Password: 'Κωδικός',
      pending: 'Αναμονή',
      PhaseCommentProposals: 'Φάση: Σχολιασμός Προτάσεων',
      PhaseResults: 'Φάση: Αποτελέσματα',
      PhaseSupportingIssue: 'Φάση: Υποστήριξη Θέματος',
      PhaseSupportingProposals: 'Φάση: Υποστήριξη Προτάσεων',
      PhaseVoteProposals: 'Φάση: Ψηφοφορία Προτάσεων',
      PostalCode: 'Ταχυδρομικός Κώδικας',
      PreviousTitle: 'Προηγούμενος Τίτλος',
      Profile: 'Προφίλ',
      ProposalComments: 'Σχόλια Πρότασης',
      ProposalHistory: 'Ιστορικό Πρότασης',
      ProposalInfo: 'Πληροφορίες Πρότασης',
      ProposalStatisticsCount: 'Στατιστικά στοιχεία πρότασης (Αριθμός)',
      ProposalSupportsCount: 'Υποστήριξη πρότασης (Αριθμός)',
      ProposalswithmyComments: 'Προτάσεις με τα σχόλιά μου',
      ProposalswithmySupports: 'Προτάσεις με την υποστήριξή μου',
      ProposalTitleandDescription: 'Τίτλος και Περιγραφή της Πρότασης',
      Proposer: 'Δημιουργός Πρότασης',
      Rankfunction: 'Συνάρτηση κατάταξης',
      rejected: 'Απορρίφθηκε',
      RejectMergerequest: 'Απόρριψη αιτήματος συγχώνευσης',
      Results: 'Αποτελέσματα',
      risDescription: 'Επιπλέον πληροφορίες',
      risLevelHint1: 'Από το excel ',
      risLevelHint2: ' π.χ. 01.01.01',
      risLevelOne: 'Κωδικός επιπέδου 3 (1η επιλογή)',
      risLevelThree: 'Κωδικός επιπέδου 3 (3η επιλογή)',
      risLevelTwo: 'Κωδικός επιπέδου 3 (2η επιλογή)',
      Roles: 'Ρόλοι',
      RunningIssues: 'Ενεργά Θέματα',
      SaveIssue: 'Αποθήκευση Θέματος',
      SaveIssueConfigs: 'Αποθήκευση παραμέτρων θέματος',
      SaveProposal: 'Αποθήκευση Πρότασης',
      searchPartner: 'Αναζήτηση εταίρων',
      searchPartnerFour: 'Εταίρος 4',
      searchPartnerOne: 'Εταίρος 1',
      searchPartnersAndProfiles: 'Αναζήτηση εταίρων και προφίλ',
      searchPartnerThree: 'Εταίρος 3',
      searchPartnerTwo: 'Εταίρος 2',
      Separetevarioussupportingmaterialurlsbycomma: 'Διαχωρίστε τα πολλαπλά url υποστηρικτικού υλικού με κόμμα',
      Settingupanewissue: ' Ρύθμιση νέου Θέματος',
      Settingupanewproposal: ' Ρύθμιση νέας Πρότασης',
      Share: 'Κοινοποίηση',
      Showcommentonothercitizens: 'Εμφάνιση σχολίων σε άλλους πολίτες',
      Showtoothercitizens: 'Εμφάνιση σε άλλους πολίτες',
      SignUp: 'Εγγραφή',
      Street: 'Οδός',
      StreetNumber: 'Αριθμός',
      sumAmountRequestedProposalsPerThematikiEnotita: 'Αιτούμενο ύψος χρηματοδότησης ανα θεματική ενότητα',
      Support: 'Υποστήριξη',
      SupportingMaterial: 'Υποστηρικτικό Υλικό',
      Supportingmaterialurlsdividedbycomma: ' Παρακαλούμε γράψτε, διευθύνσεις URL με τυχόν υποστηρικτικό υλικό, διαχωρισμένες με κόμμα (,) αν υπάρχουν. Παράδειγμα: www.test1.gr, http://www.test1.gr, www.test2.gr, http://www.test2.gr',
      SupportIssue: 'Υποστήριξη Θέματος',
      Supportissueperiodfromdate: 'Περίοδος υποστήριξης θέματος απο την ημερομηνία',
      Supportissueperiodfromtime: 'Περίοδος υποστήριξης θέματος απο την ώρα',
      Supportissueperiodtodate: 'Περίοδος υποστήριξης θέματος μέχρι την ημερομηνία',
      Supportissueperiodtotime: 'Περίοδος υποστήριξης θέματος μέχρι την ώρα',
      SupportProposal: 'Υποστήριξη Πρότασης',
      Supportproposalperiodfromdate: 'Περίοδος υποστήριξης πρότασης απο την ημερομηνία',
      Supportproposalperiodfromtime: 'Περίοδος υποστήριξης πρότασης απο την ώρα',
      Supportproposalperiodtodate: 'Περίοδος υποστήριξης πρότασης μέχρι την ημερομηνία',
      Supportproposalperiodtotime: 'Περίοδος υποστήριξης πρότασης μέχρι την ώρα',
      supportsneeded: ' αναγκαίες υποστηρίξεις',
      Tags: 'Ετικέτες',
      Theafmmustbe9characters: 'Το ΑΦΜ πρέπει να αποτελείται από 9 χαρακτήρες.',
      TheamountOfFundingRequestedmustbeinamountformat: 'Επιτρπέπεται το ποσό γραμμένο ως εξής: π.χ. 10000',
      Thedescriptionmustbegreaterthan3characters: 'Η περιγραφή πρέπει να έχει περισσότερους από 3 χαρακτήρες.',
      Thepasswordmustbebetween6and40characters: 'Ο κωδικός πρόσβασης πρέπει να είναι από 6 έως 40 χαρακτήρες.',
      ThePostalCodemustbe5characters: 'Ο Ταχυδρομικός Κώδικας πρέπει να αποτελείται από 5 χαρακτήρες.',
      ThePostalCodemustcontainonly5numbers: 'Ο Ταχυδρομικός Κώδικας πρέπει να περιέχει μόνο 5 αριθμούς.',
      TherisLevelmustbeinexcelformat: 'Επιτρπέπονται μόνο οι κωδικοί του excel',
      Thetitlemustbegreaterthan3characters: 'Ο τίτλος πρέπει να έχει περισσότερους από 3 χαρακτήρες.',
      Theusernamemustbebetween3and20characters: 'Το όνομα χρήστη πρέπει να αποτελείται από 3 έως 20 χαρακτήρες.',
      Thisfieldisrequired: 'Αυτό το πεδίο είναι υποχρεωτικό!',
      Thisisnotavalidemail: 'Αυτό δεν είναι έγκυρο email.',
      Thisissueis: 'Αυτό το θέμα είναι',
      TitleofProposal: ' Τίτλος Πρότασης',
      TitleofProposalandDescription: ' Τίτλος Πρότασης και Περιγραφή',
      TotalComments: 'Σύνολο σχολίων: ',
      TotalVotes: 'Σύνολο ψήφων',
      universityProfessor: 'Καθηγητής Πανεπιστημίου',
      Update: 'Ενημέρωση',
      UpdateProfileForRis3: "Για την συμμετοχή σας στην διαβούλευση που επιλέξατε θα χρειαστεί να καταχωρήσετε τις ακόλουθες πληροφορίες στο προφίλ σας",
      use: 'χρήση',
      User: 'Χρήστης',
      Username: 'Όνομα χρήστη',
      Verification: 'Επαλήθευση',
      Verify: 'Eπαληθεύω',
      verify: 'Επιβεβαίωση',
      views: 'προβολές',
      Views: 'Προβολές',
      Vote: 'Ψήφος',
      VoteAnalysis: 'Ανάλυση Ψήφων Θέματος',
      VoteHere: 'Ψήφισε εδώ',
      Voteproposalperiodfromdate: 'Περίοδος ψηφοφορίας πρότασης από την ημερομηνία',
      Voteproposalperiodfromtime: 'Περίοδος ψηφοφορίας πρότασης από την ώρα',
      Voteproposalperiodtodate: 'Περίοδος ψηφοφορίας πρότασης μέχρι την ημερομηνία',
      Voteproposalperiodtotime: 'Περίοδος ψηφοφορίας πρότασης μέχρι την ώρα',
      VotesperIssue: 'Ψήφοι ανά Θέμα',
      Worstproposalborderline: 'Η χειρότερη οριακή πρόταση ',
      Youcancreateamergeproposalwhenanswertoallmergerequests: 'Μπορείτε να δημιουργήσετε μια πρόταση συγχώνευσης όταν απαντήσετε σε όλα τα αιτήματα συγχώνευσης',
      Youmayentermorethanoneproposal: 'Μπορείτε να εισάγετε περισσότερες από μία προτάσεις',
    },
    home: {
      Login: 'Σύνδεση',
      podDescription: 'Το PODS είναι ένα σύστημα δημόσιας διαβούλευσης που ενσωματώνει ένα σύνολο θεμελιωδών, προηγμένων και καινοτόμων ζητημάτων σχετικά με ένα δημόσιο διαδικτυακό σύστημα διαβούλευσης, βασισμένο στις βασικές αρχές Δημόσιας Διαβούλευσης.',
      signUp: 'Εγγραφή',
      title: 'Δημόσιο Διαδικτυακό Σύστημα Διαβούλευσης',
    },
    header: {
      AdminBoard: 'Οθόνη Διαχειριστή',
      CitizenBoard: 'Οθόνη Πολίτη',
      LogOut: 'Αποσύνδεση',
      Mycomments: 'Τα σχόλιά μου',
      MySupports: 'Οι υποστηρίξεις μου',
      ProposerBoard: 'Οθόνη Δημιουργού Πρότασης',
    },
    footer: {
      Help: 'Οδηγίες Χρήσης'
    },
  },
  en: {
    main: {
      accepted: 'accepted',
      AcceptMergerequest: 'Accept Merge request',
      Actions: 'Actions',
      AddComment: 'Add Comment',
      Address: 'Address',
      Afm: 'AFM Tax Number',
      amountOfFundingRequested: 'Amount of Funding Requested',
      amountOfFundingRequestedHint: 'e.g. 10000',
      and: 'and',
      andcurrentRoundis: 'and current Round is',
      Back: 'Back',
      business: 'Business',
      Changedescriptionofissue: 'Change description of issue',
      ChangeTitleandDescriptionofProposal: 'Change Title and Description of Proposal',
      Changetitleofissue: 'Change title of issue',
      Check: 'Check',
      City: 'City',
      closed: 'closed',
      ClosedIssues: 'Closed Issues',
      Comment: 'Comment',
      CommentInHistoryWithVerification: ' Comment In History With Verification: ',
      Commentproposalperiodfromdate: 'Comment proposal period from date',
      Commentproposalperiodfromtime: 'Comment proposal period from time',
      Commentproposalperiodtodate: 'Comment proposal period to date',
      Commentproposalperiodtotime: 'Comment proposal period to time',
      CommentsInHistory: ' Comments In History: ',
      Config: 'Config',
      countProposalsPerDay: 'Number of proposals per day',
      countProposalsPerThematikiEnotita: 'Number of proposals per topic',
      CreateComment: 'Create Comment',
      CreateIssue: 'Create Issue',
      Createnewissue: 'Create new issue',
      CreateNextRound: 'Create Next Round',
      CreateProposal: 'Create Proposal',
      Creator: 'Creator',
      CurrentRound: 'Current Round',
      CurrentTitle: 'Current Title',
      deleted: 'deleted',
      DeleteMergerequest: 'Delete Merge request',
      description: 'Description',
      Detailsofmymergerequest: 'Details of my merge request',
      Detailsofmyproposalmergerequests: 'Details of my proposal merge requests',
      Detailsofmyproposalmergerequestshistory: 'Details of my proposal merge requests history',
      DetailsofproposalcreatorProposalORmergeRequestProposalsmergerequestshistory: 'Details of proposal (creatorProposal OR mergeRequestProposals) merge requests history',
      Dislike: 'Dislike',
      Dislikes: 'Dislikes',
      DonotVerify: 'Do not Verify',
      duration: 'Duration in months',
      Edit: 'Edit',
      EditIssue: 'Edit Issue',
      EditProposal: 'Edit Proposal',
      eg: 'eg',
      eg3lastrankedproposalnotcontinuetootherphases: 'eg. 3 last ranked proposal not continue to other phases',
      emailVerification: 'E-mail Verification',
      exportProposalsToExcel: 'Export Proposals to Excel',
      FinalActiveProposals: 'Final Active Proposals',
      FinalizeDeliberation: 'Finalize Deliberation',
      FinalParticipants: 'Final Participants',
      fordislike: 'for dislike',
      forlike: 'for like',
      forneutral: 'for neutral',
      Frommergerequestcreator: 'from merge request creator',
      FutureIssues: 'Future Issues',
      HandleDeliberation: 'Handle Deliberation',
      helpInfo: 'Application instructions',
      Inordertosubmityourownproposalpress: 'In order to submit your own proposal press',
      instituteResearcher: 'Institute Researcher',
      Issue: 'Issue',
      IssueConfig: 'Issue Config',
      IssueDescription: 'Issue Description',
      IssueHistory: 'Issue History',
      IssueInfo: 'Issue Info',
      issueLimits: 'issue Limits',
      IssueProposals: 'Issue Proposals',
      IssueResults: 'Issue Results',
      Issues: 'Issues',
      Issuesfordeliberation: 'Issues for deliberation',
      IssueTitle: 'Issue Title',
      JobTitle: 'Job Title (researchers put Institute or laboratory and Professors department/University)',
      Like: 'Like',
      Likes: 'Likes',
      maturityDocumentation: 'Maturity Documentation',
      maturityStage: 'Maturity Stage',
      MaxDislikes: 'Max Dislikes',
      MaxLikes: 'Max Likes',
      MaxNeutral: 'Max Neutral',
      Mergemyproposalwith: 'Merge my proposal with',
      MergeProposal: 'Merge Proposal',
      MergeProposals: 'Merge Proposals',
      Mergerequestaccept: 'Merge request accept',
      mergerequestProposalswithProposers: 'merge request Proposals with Proposers',
      MergeRequests: 'Merge Requests',
      MergeRequestsHistory: 'Merge Requests History',
      Mergerequeststatus: 'Merge request status',
      Minissuesupports: 'Min issue supports',
      Minproposalsupports: 'Min proposal supports',
      MyProposal: 'My Proposal',
      MyVotes: 'My votes',
      Neutral: 'Neutral',
      newRisLevel: 'If the Level 3 Code you want is not included in EXCEL, you can submit yours',
      newRisLevelDescription: 'New Ris Level Description',
      NoavailableClosedIssues: 'No available Closed Issues',
      NoavailableFuturesIssues: 'No available Futures Issues',
      NoavailableIssueProposals: 'No available Issue Proposals',
      NoavailableOtherProposals: 'No available Other Proposals',
      NoavailableProposalComments: 'No available Proposal Comments',
      NoavailableProposalHistory: 'No available Proposal History',
      NoavailableProposalswithmyComments: 'No available Proposals with my Comments',
      NoavailableProposalswithmySupports: 'No available Proposals with my Supports',
      NoavailableRunningIssues: 'No available Running Issues',
      NotShowtoothercitizens: 'Not Show to other citizens',
      Occupation: 'Occupation',
      open: 'open',
      OpenFinalizedDeliberation: 'Open Finalized Deliberation',
      Password: 'Password',
      pending: 'pending',
      PhaseCommentProposals: 'Phase: Comment Proposals',
      PhaseResults: 'Phase: Results',
      PhaseSupportingIssue: 'Phase: Supporting Issue',
      PhaseSupportingProposals: 'Phase: Supporting Proposals',
      PhaseVoteProposals: 'Phase: Vote Proposals',
      PostalCode: 'Postal Code',
      PreviousTitle: 'Previous Title',
      Profile: 'Profile',
      ProposalComments: 'Proposal Comments',
      ProposalHistory: 'Proposal History',
      ProposalInfo: 'Proposal Info',
      ProposalStatisticsCount: 'Proposal Statistics (Count)',
      ProposalSupportsCount: 'Proposal Supports (Count)',
      ProposalswithmyComments: 'Proposals with my Comments',
      ProposalswithmySupports: 'Proposals with my Supports',
      ProposalTitleandDescription: 'Proposal Title and Description',
      Proposer: 'Proposer',
      Rankfunction: 'Rank function',
      rejected: 'rejected',
      RejectMergerequest: 'Reject Merge request',
      Results: 'Results',
      risDescription: 'Additional Information',
      risLevelHint1: 'From excel ',
      risLevelHint2: ' e.g. 01.01.01',
      risLevelOne: 'Level 3 code (1st choice)',
      risLevelThree: 'Level 3 code (3rd choice)',
      risLevelTwo: 'Level 3 code (2nd option)',
      Roles: 'Roles',
      RunningIssues: 'Running Issues',
      SaveIssue: 'Save Issue',
      SaveIssueConfigs: 'Save Issue Configs',
      SaveProposal: 'Save Proposal',
      searchPartner: 'Search partner',
      searchPartnerFour: 'Partner 4',
      searchPartnerOne: 'Partner 1',
      searchPartnersAndProfiles: 'Search partners and profiles',
      searchPartnerThree: 'Partner 3',
      searchPartnerTwo: 'Partner 2',
      Separetevarioussupportingmaterialurlsbycomma: 'Separete various supporting material urls by comma',
      Settingupanewissue: ' Setting up a new issue',
      Settingupanewproposal: ' Setting up a new proposal',
      Share: 'Share',
      Showcommentonothercitizens: 'Show comment on other citizens',
      Showtoothercitizens: 'Show to other citizens',
      SignUp: 'Sign Up',
      Street: 'Street',
      StreetNumber: 'Street Number',
      sumAmountRequestedProposalsPerThematikiEnotita: 'Sum amount requested of proposals per topic',
      Support: 'Support',
      SupportingMaterial: 'Supporting Material',
      Supportingmaterialurlsdividedbycomma: 'Please write URLs of any supporting material, separated by commas (,) if any. Example: www.test1.gr, http://www.test1.gr, www.test2.gr, http://www.test2.gr',
      SupportIssue: 'Support Issue',
      Supportissueperiodfromdate: 'Support issue period from date',
      Supportissueperiodfromtime: 'Support issue period from time',
      Supportissueperiodtodate: 'Support issue period to date',
      Supportissueperiodtotime: 'Support issue period to time',
      SupportProposal: 'Support Proposal',
      Supportproposalperiodfromdate: 'Support proposal period from date',
      Supportproposalperiodfromtime: 'Support proposal period from time',
      Supportproposalperiodtodate: 'Support proposal period to date',
      Supportproposalperiodtotime: 'Support proposal period to time',
      supportsneeded: ' supports needed',
      Tags: 'Tags',
      Theafmmustbe9characters: 'The AFM tax number must be 9 characters.',
      Thedescriptionmustbegreaterthan3characters: 'The description must be greater than 3 characters.',
      Thepasswordmustbebetween6and40characters: 'The password must be between 6 and 40 characters.',
      ThePostalCodemustbe5characters: 'The Postal Code must be 5 characters.',
      ThePostalCodemustcontainonly5numbers: 'The Postal Code must contain only 5 numbers.',
      Thetitlemustbegreaterthan3characters: 'The title must be greater than 3 characters.',
      Theusernamemustbebetween3and20characters: 'The username must be between 3 and 20 characters.',
      Thisfieldisrequired: 'This field is required!',
      Thisisnotavalidemail: 'This is not a valid email.',
      Thisissueis: 'This issue is',
      TitleofProposal: ' Title of Proposal and Description',
      TitleofProposalandDescription: ' Title of Proposal and Description',
      TotalComments: 'Total Comments: ',
      TotalVotes: 'Total Votes',
      universityProfessor: 'University Professor',
      Update: 'Update',
      UpdateProfileForRis3: "To participate in the consultation you have chosen, you will need to enter the following information in your profile",
      use: 'use',
      User: 'User',
      Username: 'Username',
      Verification: 'Verification',
      verify: 'Verify',
      Verify: 'Verify',
      views: 'views',
      Views: 'Views',
      Vote: 'Vote',
      VoteAnalysis: 'Vote Analysis',
      VoteHere: 'Vote Here',
      Voteproposalperiodfromdate: 'Vote proposal period from date',
      Voteproposalperiodfromtime: 'Vote proposal period from time',
      Voteproposalperiodtodate: 'Vote proposal period to date',
      Voteproposalperiodtotime: 'Vote proposal period to time',
      VotesperIssue: 'Votes per Issue',
      Worstproposalborderline: 'Worst proposal borderline',
      Youcancreateamergeproposalwhenanswertoallmergerequests: 'You can create a merge proposal when answer to all merge requests',
      Youmayentermorethanoneproposal: 'You may enter more than one proposal',
    },
    home: {
      Login: 'Login',
      podDescription: 'PODS is a public deliberation system which incorporates a set of fundamental, advanced and innovative issues regarding a public online deliberation system, based on Public Deliberation basic principles.',
      signUp: 'Sign Up',
      title: 'Public Online Deliberation System',
    },
    header: {
      AdminBoard: 'Admin Board',
      CitizenBoard: 'CitizenBoard',
      LogOut: 'LogOut',
      Mycomments: 'My comments',
      MySupports: 'My Supports',
      ProposerBoard: 'Proposer Board',
    },
    footer: {
      Help: 'Help'
    },
  }
}

const I18n = createI18n(
  locales,
  translations,
);

export default I18n;