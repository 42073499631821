import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposerProposalCommentEditService from "../services/proposer.proposal.comment.edit.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';
import RISLEVEL from '../data/risLevel.json';

const required = value => {
  if (!value) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thisfieldisrequired" />
      </p>
    );
  }
};

const vtitle = value => {
  if (value.length < 3) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thetitlemustbegreaterthan3characters" />
      </p>
    );
  }
};

const vrisLevel = value => {
  if (value.length > 0 && !/(00\.00\.00|^(0[1-8]|0[1-9]|1[0-1])\.(0[1-9]|1[0-1])\.(0[1-9]|1[0-9]|2[0-2]))$/.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        <I18n t="main.TherisLevelmustbeinexcelformat" />
      </div>
    );
  }
};

const vamountOfFundingRequested = value => {
  if (value.length > 0 && !/^[0-9]*?$/.test(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        <I18n t="main.TheamountOfFundingRequestedmustbeinamountformat" />
      </div>
    );
  }
};

export default class ProposerProposalCommentEdit extends Component {
  constructor(props) {
    super(props);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeListComment = this.onChangeListComment.bind(this);
    this.onChangeRisLevelOne = this.onChangeRisLevelOne.bind(this);
    this.onChangeRisLevelTwo = this.onChangeRisLevelTwo.bind(this);
    this.onChangeRisLevelThree = this.onChangeRisLevelThree.bind(this);
    this.onChangeAmountOfFundingRequested = this.onChangeAmountOfFundingRequested.bind(this);
    this.onChangeMaturityStage = this.onChangeMaturityStage.bind(this);
    this.onChangeRisDescription = this.onChangeRisDescription.bind(this);
    this.onChangeSearchPartnersAndProfiles = this.onChangeSearchPartnersAndProfiles.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeTrl = this.onChangeTrl.bind(this);
    this.onChangeDuration = this.onChangeDuration.bind(this);
    this.onChangeNewRisLevel = this.onChangeNewRisLevel.bind(this);
    this.onChangeNewRisLevelDescription = this.onChangeNewRisLevelDescription.bind(this);
    this.onChangeSearchPartner = this.onChangeSearchPartner.bind(this);
    this.onChangeSearchPartnerOne = this.onChangeSearchPartnerOne.bind(this);
    this.onChangeSearchPartnerTwo = this.onChangeSearchPartnerTwo.bind(this);
    this.onChangeSearchPartnerThree = this.onChangeSearchPartnerThree.bind(this);
    this.onChangeSearchPartnerFour = this.onChangeSearchPartnerFour.bind(this);

    this.state = {
      title: "",
      oldTitle: "",
      userId: AuthService.getCurrentUser().id,
      proposalId: this.props.match.params.proposalId,
      proposal: null,
      proposerName: null,
      proposalComments: null,
      listComments: [],
      risLevelOne: "",
      risLevelOneData: "",
      risLevelTwo: "",
      risLevelTwoData: "",
      risLevelThree: "",
      risLevelThreeData: "",
      amountOfFundingRequested: "",
      maturityStage: "",
      risDescription: "",
      searchPartnersAndProfiles: "",
      description: "",
      trl: "",
      duration: "",
      newRisLevel: false,
      newRisLevelDescription: "",
      searchPartner: false,
      searchPartnerOne: "",
      searchPartnerTwo: "",
      searchPartnerThree: "",
      searchPartnerFour: "",
      successful: false,
      message: ""
    };
  }

  componentDidMount() {
    ProposerProposalCommentEditService.getProposal(this.state.proposalId).then(
      response => {
        this.setState({
          proposal: response.data.message,
          title: response.data.message[0].title
        });
        if(response.data.message[0].issue[0].hasOwnProperty('isForRis3') && response.data.message[0].issue[0].isForRis3 === true){
          this.setState({
            risLevelOne: response.data.message[0].risLevelOne,
            risLevelOneData: response.data.message[0].risLevelOne !== '' ? RISLEVEL[response.data.message[0].risLevelOne]: '',
            risLevelTwo: response.data.message[0].risLevelTwo,
            risLevelTwoData: response.data.message[0].risLevelTwo !== '' ? RISLEVEL[response.data.message[0].risLevelTwo]: '',
            risLevelThree: response.data.message[0].risLevelThree,
            risLevelThreeData: response.data.message[0].risLevelThree !== '' ? RISLEVEL[response.data.message[0].risLevelThree]: '',
            amountOfFundingRequested: response.data.message[0].amountOfFundingRequested,
            maturityStage: response.data.message[0].maturityStage,
            risDescription: response.data.message[0].risDescription,
            searchPartnersAndProfiles: response.data.message[0].searchPartnersAndProfiles,
            description: response.data.message[0].description,
            trl: response.data.message[0].trl,
            duration: response.data.message[0].duration,
            newRisLevel: response.data.message[0].newRisLevel,
            newRisLevelDescription: response.data.message[0].newRisLevelDescription,
            searchPartner: response.data.message[0].searchPartner,
            searchPartnerOne: response.data.message[0].searchPartnerOne,
            searchPartnerTwo: response.data.message[0].searchPartnerTwo,
            searchPartnerThree: response.data.message[0].searchPartnerThree,
            searchPartnerFour: response.data.message[0].searchPartnerFour,
          });
        }
      },
      error => {
        this.setState({
          proposal:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerProposalCommentEditService.getProposalProposer(this.state.proposalId).then(
      response => {
        this.setState({
          proposerName: response.data.message
        });
      },
      error => {
        this.setState({
          proposerName:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerProposalCommentEditService.getProposalComments(this.state.proposalId).then(
      response => {
        this.setState({
          proposalComments: response.data.message
        });
      },
      error => {
        this.setState({
          proposalComments:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
      oldTitle: e.target.dataset.oldTitle
    });
  }

  onChangeListComment(e) {
    // current array of options
    const listComments = this.state.listComments
    let index

    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array
      listComments.push(e.target.value)
    } else {
      // or remove the value from the unchecked checkbox from the array
      index = listComments.indexOf(e.target.value)
      listComments.splice(index, 1)
    }

    this.setState({
      listComments: listComments
    });
  }

  onChangeRisLevelOne(e) {
    this.setState({
      risLevelOne: e.target.value,
      risLevelOneData: RISLEVEL[e.target.value]
    });
  }

  onChangeRisLevelTwo(e) {
    this.setState({
      risLevelTwo: e.target.value,
      risLevelTwoData: RISLEVEL[e.target.value]
    });
  }

  onChangeRisLevelThree(e) {
    this.setState({
      risLevelThree: e.target.value,
      risLevelThreeData: RISLEVEL[e.target.value]
    });
  }

  onChangeAmountOfFundingRequested(e) {
    const inputAmount = e.target.value;
    // Remove non-digit characters from the input
    const sanitizedAmount = inputAmount.replace(/[^\d.]/g, '');
    this.setState({
      amountOfFundingRequested: sanitizedAmount
    });
  }

  onChangeMaturityStage(e) {
    this.setState({
      maturityStage: e.target.value
    });
  }

  onChangeRisDescription(e) {
    this.setState({
      risDescription: e.target.value
    });
  }

  onChangeSearchPartnersAndProfiles(e) {
    this.setState({
      searchPartnersAndProfiles: e.target.value
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  onChangeTrl(e) {
    this.setState({
      trl: e.target.value,
    });
  }

  onChangeDuration(e) {
    this.setState({
      duration: e.target.value,
    });
  }

  onChangeNewRisLevel(e) {
    if(e.target.checked){
      this.setState({
        risLevelOne: "",
        risLevelOneData: "",
        risLevelTwo: "",
        risLevelTwoData: "",
        risLevelThree: "",
        risLevelThreeData: "",
      });
    }else{
      this.setState({
        newRisLevelDescription: "",
      });
    }
    this.setState({
      newRisLevel: e.target.checked,
    });
  }

  onChangeNewRisLevelDescription(e) {
    this.setState({
      newRisLevelDescription: e.target.value,
    });
  }

  onChangeSearchPartner(e) {
    if(!e.target.checked){
      this.setState({
        searchPartnersAndProfiles: ""
      });
    }
    this.setState({
      searchPartner: e.target.checked
    });
  }

  onChangeSearchPartnerOne(e) {
    this.setState({
      searchPartnerOne: e.target.value
    });
  }

  onChangeSearchPartnerTwo(e) {
    this.setState({
      searchPartnerTwo: e.target.value
    });
  }

  onChangeSearchPartnerThree(e) {
    this.setState({
      searchPartnerThree: e.target.value
    });
  }

  onChangeSearchPartnerFour(e) {
    this.setState({
      searchPartnerFour: e.target.value
    });
  }

  handleUpdate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      if(this.state.proposal && this.state.proposal[0].issue[0].hasOwnProperty('isForRis3') && this.state.proposal[0].issue[0].isForRis3 === true){
        if(!this.state.newRisLevel && this.state.risLevelOne === ''){
          this.setState({
            successful: false,
            message: 'Εφόσον δεν έχετε επιλέξει προσθήκη νέου επιπέδου θα χρειαστεί να καταχωρήσετε την 1η επιλογή κωδικού επιπέδου 3'
          });
          return;
        }
        if(this.state.newRisLevel && this.state.newRisLevelDescription === ''){
          this.setState({
            successful: false,
            message: 'Εφόσον έχετε επιλέξει την προσθήκη νέου επιπέδου θα χρειαστεί να καταχωρήσετε την περιγραφή του'
          });
          return;
        }
        if(this.state.searchPartner && this.state.searchPartnersAndProfiles === ''){
          this.setState({
            successful: false,
            message: 'Εφόσον έχετε επιλέξει την αναζήτηση εταίρων θα χρειαστεί να καταχωρήσετε τουλάχιστον και το πεδίο Αναζήτηση εταίρων και προφίλ'
          });
          return;
        }
        ProposerProposalCommentEditService.updateRis3(
          this.state.title,
          this.state.oldTitle,
          this.state.listComments,
          this.state.risLevelOne,
          this.state.risLevelTwo,
          this.state.risLevelThree,
          this.state.amountOfFundingRequested,
          this.state.maturityStage,
          this.state.risDescription,
          this.state.searchPartnersAndProfiles,
          this.state.description,
          this.state.trl,
          this.state.duration,
          this.state.newRisLevel,
          this.state.newRisLevelDescription,
          this.state.searchPartner,
          this.state.searchPartnerOne,
          this.state.searchPartnerTwo,
          this.state.searchPartnerThree,
          this.state.searchPartnerFour,
          this.state.userId,
          this.state.proposalId
        ).then(
          response => {
            this.setState({
              message: response.data.message,
              successful: true
            });
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            this.setState({
              successful: false,
              message: resMessage
            });
          }
        );
      }else{
        ProposerProposalCommentEditService.update(
          this.state.title,
          this.state.oldTitle,
          this.state.listComments,
          this.state.userId,
          this.state.proposalId
        ).then(
          response => {
            this.setState({
              message: response.data.message,
              successful: true
            });
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            this.setState({
              successful: false,
              message: resMessage
            });
          }
        );
      }
    }
  }

  render() {
    const proposal = this.state.proposal;
    const proposer = this.state.proposerName;
    const proposalComments = this.state.proposalComments;
    return (
      <>
        <Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/proposer"><I18n t="main.Issues" /></Link>
                } />
              </li>
              <li className="govgr-breadcrumbs__list-item">
                {
                  proposal ?
                    Object.keys(proposal).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/proposer/issue/" + proposal[key].issue[0]._id}>{proposal[key].issue[0].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
              <li className="govgr-breadcrumbs__list-item">
                {
                  proposal ?
                    Object.keys(proposal).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/proposer/proposal/" + proposal[key]._id}>{proposal[key].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
            </ol>
          </div>
          {!this.state.successful && (
            <>
              {
                proposal ?
                  Object.keys(proposal).map(
                    key => {
                      return <div key={key}>
                        <h1 className="govgr-heading-l"><I18n t="main.EditProposal" />: {proposal[key].title}</h1>
                        <Form
                          onSubmit={this.handleUpdate}
                          ref={c => {
                            this.form = c;
                          }}
                        >
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m">
                                {
                                  (proposal[key].issue && proposal[key].issue[0].hasOwnProperty('isForRis3') && proposal[key].issue[0].isForRis3 === true) ? (
                                    <I18n t="main.TitleofProposal" />
                                  ) : (
                                    <I18n t="main.TitleofProposalandDescription" />
                                  )
                                }
                              </span>
                              <p className="govgr-hint"><I18n t="main.ChangeTitleandDescriptionofProposal" /></p>
                              { proposer ?(
                                  <><I18n t="main.Proposer" />: {proposer}</>
                                ) : <Loading />
                              }
                              <Textarea
                                className="govgr-textarea"
                                rows="5"
                                name="proposal"
                                value={proposal[key].title}
                                data-old-title={proposal[key].title}
                                onChange={this.onChangeTitle}
                                validations={[required, vtitle]}
                              />
                            </label>
                          </div>
                          <h3><I18n t="main.ProposalComments" /></h3>
                          <div>
                            <ul>
                              {
                                proposalComments ?
                                  Object.keys(proposalComments).length > 0 ?
                                    Object.keys(proposalComments).map(
                                      key => {
                                        return <div className="govgr-checkboxes" key={key}>
                                          <div className="govgr-checkboxes__item">
                                            <label className="govgr-label govgr-checkboxes__label">
                                              {proposalComments[key].title}
                                              <Input
                                                className="govgr-checkboxes__input"
                                                type="checkbox"
                                                name={key}
                                                value={proposalComments[key]._id}
                                                onChange={this.onChangeListComment}
                                              />
                                            </label>
                                          </div>
                                        </div>;
                                      }
                                    )
                                    : <I18n t="main.NoavailableProposalComments" />
                                  : "Loading..."
                              }
                            </ul>
                          </div>
                          {
                            (proposal[key].issue && proposal[key].issue[0].hasOwnProperty('isForRis3') && proposal[key].issue[0].isForRis3 === true) ? (
                              <div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.description" /></span>
                                    <Textarea
                                      className="govgr-textarea"
                                      rows="5"
                                      name="description"
                                      value={this.state.description}
                                      onChange={this.onChangeDescription}
                                      validations={[required]}
                                    />
                                  </label>
                                </div>
                                <div>
                                  <p> Excel με κωδικούς επιπέδων: 
                                    <a href="/assets/ΤΟΜΕΙΣ ΕΣΕΕ.xlsx" download>ΤΟΜΕΙΣ ΕΣΕΕ</a>
                                  </p>
                                </div>
                                {!this.state.newRisLevel && this.state.risLevelOne !== '' && (
                                  <>
                                    <div className="govgr-field">
                                      <label className="govgr-label">
                                        <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.risLevelOne" /></span>
                                        <p className="govgr-hint">
                                          <I18n t="main.risLevelHint1" /><a href="/assets/ΤΟΜΕΙΣ ΕΣΕΕ.xlsx" download>ΕΣΕΕ</a><I18n t="main.risLevelHint2" />
                                        </p>
                                        <Input
                                          type="text"
                                          className="govgr-input"
                                          name="risLevelOne"
                                          value={this.state.risLevelOne}
                                          onChange={this.onChangeRisLevelOne}
                                          validations={[required, vrisLevel]}
                                        />
                                      </label>
                                      {this.state.risLevelOneData !== '' && (<p className="govgr-hint">{this.state.risLevelOneData}</p>)}
                                    </div>
                                    <div className="govgr-field">
                                      <label className="govgr-label">
                                        <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.risLevelTwo" /></span>
                                        <p className="govgr-hint">
                                          <I18n t="main.risLevelHint1" /><a href="/assets/ΤΟΜΕΙΣ ΕΣΕΕ.xlsx" download>ΕΣΕΕ</a><I18n t="main.risLevelHint2" />
                                        </p>
                                        <Input
                                          type="text"
                                          className="govgr-input"
                                          name="risLevelTwo"
                                          value={this.state.risLevelTwo}
                                          onChange={this.onChangeRisLevelTwo}
                                          validations={[vrisLevel]}
                                        />
                                      </label>
                                      {this.state.risLevelTwoData !== '' && (<p className="govgr-hint">{this.state.risLevelTwoData}</p>)}
                                    </div>
                                    <div className="govgr-field">
                                      <label className="govgr-label">
                                        <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.risLevelThree" /></span>
                                        <p className="govgr-hint">
                                          <I18n t="main.risLevelHint1" /><a href="/assets/ΤΟΜΕΙΣ ΕΣΕΕ.xlsx" download>ΕΣΕΕ</a><I18n t="main.risLevelHint2" />
                                        </p>
                                        <Input
                                          type="text"
                                          className="govgr-input"
                                          name="risLevelThree"
                                          value={this.state.risLevelThree}
                                          onChange={this.onChangeRisLevelThree}
                                          validations={[vrisLevel]}
                                        />
                                      </label>
                                      {this.state.risLevelThreeData !== '' && (<p className="govgr-hint">{this.state.risLevelThreeData}</p>)}
                                    </div>
                                  </>
                                )}
                                <div className="govgr-checkboxes">
                                  <div className="govgr-checkboxes__item">
                                    <label className="govgr-label govgr-checkboxes__label">
                                      <I18n t="main.newRisLevel" />
                                      <Input
                                        className="govgr-checkboxes__input"
                                        type="checkbox"
                                        name="newRisLevel"
                                        checked={this.state.newRisLevel}
                                        onChange={this.onChangeNewRisLevel}
                                      />
                                    </label>
                                  </div>
                                </div>
                                {this.state.newRisLevel && (
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.newRisLevelDescription" /></span>
                                      <Textarea
                                        className="govgr-textarea"
                                        rows="5"
                                        name="newRisLevelDescription"
                                        value={this.state.newRisLevelDescription}
                                        onChange={this.onChangeNewRisLevelDescription}
                                      />
                                    </label>
                                  </div>
                                )}
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.amountOfFundingRequested" /></span>
                                    <p className="govgr-hint"><I18n t="main.amountOfFundingRequestedHint" /></p>
                                    <Input
                                      type="text"
                                      className="govgr-input"
                                      name="amountOfFundingRequested"
                                      value={this.state.amountOfFundingRequested}
                                      onChange={this.onChangeAmountOfFundingRequested}
                                      validations={[required, vamountOfFundingRequested]}
                                    />
                                    {parseFloat(this.state.amountOfFundingRequested) ? (
                                      <div>{parseFloat(this.state.amountOfFundingRequested).toLocaleString('el-GR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</div>
                                    ) : null}
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main." /><I18n t="main.duration" /></span>
                                    <Input
                                      type="number"
                                      className="govgr-input"
                                      name="duration"
                                      min="0"
                                      step="1"
                                      value={this.state.duration}
                                      onChange={this.onChangeDuration}
                                      validations={[required]}
                                    />
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.maturityStage" /></span>
                                    <select
                                      name="trl"
                                      className="govgr-select"
                                      value={this.state.trl}
                                      onChange={this.onChangeTrl}
                                      validations={[required]}
                                    >
                                      <option value="">----</option>
                                      <option value="1">TRL 1 - Βασικές αρχές παρατηρήθηκαν</option>
                                      <option value="2">TRL 2 - Διατύπωση της έννοιας της τεχνολογίας</option>
                                      <option value="3">TRL 3 - Πειραματική απόδειξη της ιδέας</option>
                                      <option value="4">TRL 4 - Τεχνολογία επικυρωμένη στο εργαστήριο</option>
                                      <option value="5">TRL 5 - Τεχνολογία επικυρωμένη στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)</option>
                                      <option value="6">TRL 6 - Τεχνολογία που επιδεικνύεται στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)</option>
                                      <option value="7">TRL 7 - Επίδειξη πρωτοτύπου συστήματος σε επιχειρησιακό περιβάλλον</option>
                                      <option value="8">TRL 8 - Το σύστημα ολοκληρώθηκε και πιστοποιήθηκε</option>
                                      <option value="9">TRL 9 - Πραγματικό σύστημα αποδεδειγμένο σε επιχειρησιακό περιβάλλον (ανταγωνιστική κατασκευή στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής· ή στο διάστημα)</option>
                                    </select>
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.maturityDocumentation" /></span>
                                    <Textarea
                                      className="govgr-textarea"
                                      rows="5"
                                      name="maturityStage"
                                      value={this.state.maturityStage}
                                      onChange={this.onChangeMaturityStage}
                                      validations={[required]}
                                    />
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.risDescription" /></span> 
                                    <Textarea
                                      className="govgr-textarea"
                                      rows="5"
                                      name="risDescription"
                                      value={this.state.risDescription}
                                      onChange={this.onChangeRisDescription}
                                    />
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.searchPartnerOne" /></span>
                                    <Textarea
                                      className="govgr-textarea"
                                      rows="2"
                                      name="searchPartnerOne"
                                      value={this.state.searchPartnerOne}
                                      onChange={this.onChangeSearchPartnerOne}
                                    />
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.searchPartnerTwo" /></span>
                                    <Textarea
                                      className="govgr-textarea"
                                      rows="2"
                                      name="searchPartnerTwo"
                                      value={this.state.searchPartnerTwo}
                                      onChange={this.onChangeSearchPartnerTwo}
                                    />
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.searchPartnerThree" /></span>
                                    <Textarea
                                      className="govgr-textarea"
                                      rows="2"
                                      name="searchPartnerThree"
                                      value={this.state.searchPartnerThree}
                                      onChange={this.onChangeSearchPartnerThree}
                                    />
                                  </label>
                                </div>
                                <div className="govgr-field">
                                  <label className="govgr-label">
                                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.searchPartnerFour" /></span>
                                    <Textarea
                                      className="govgr-textarea"
                                      rows="2"
                                      name="searchPartnerFour"
                                      value={this.state.searchPartnerFour}
                                      onChange={this.onChangeSearchPartnerFour}
                                    />
                                  </label>
                                </div>
                                <div className="govgr-checkboxes">
                                  <div className="govgr-checkboxes__item">
                                    <label className="govgr-label govgr-checkboxes__label">
                                      <I18n t="main.searchPartner" />
                                      <Input
                                        className="govgr-checkboxes__input"
                                        type="checkbox"
                                        name="searchPartner"
                                        checked={this.state.searchPartner}
                                        onChange={this.onChangeSearchPartner}
                                      />
                                    </label>
                                  </div>
                                </div>
                                {this.state.searchPartner && (
                                  <>
                                    <div className="govgr-field">
                                      <label className="govgr-label">
                                        <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.searchPartnersAndProfiles" /></span>
                                        <Textarea
                                          className="govgr-textarea"
                                          rows="5"
                                          name="searchPartnersAndProfiles"
                                          value={this.state.searchPartnersAndProfiles}
                                          onChange={this.onChangeSearchPartnersAndProfiles}
                                        />
                                      </label>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : ""
                          }
                          <div className="govgr-button-group">
                            <button className="govgr-btn govgr-btn-primary">
                              <I18n t="main.SaveProposal" />
                            </button>
                            <Route path={this.props.base} render={() =>
                              <Link key={key} className="govgr-btn govgr-btn-secondary mt-2" to={"/proposer/proposal/" + proposal[key]._id}><I18n t="main.Back" /></Link>
                            } />
                          </div>

                          <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>
                      </div>;
                    }
                  )
                  :
                  <Loading />
              }
            </>
          )}

          {this.state.message && (
            <div className="form-group">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}<br />
                {
                  proposal ?
                    Object.keys(proposal).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-btn govgr-btn-secondary mt-2" to={"/proposer/proposal/" + proposal[key]._id}><I18n t="main.Back" /></Link>
                        } />;
                      }
                    )
                    :
                    <Loading />
                }
              </div>
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}
