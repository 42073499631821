import React, { Component } from "react";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import CitizenSupportProposalService from "../services/citizen.support.proposal.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';
import RISLEVEL from '../data/risLevel.json';

const trl = {
  '1': 'TRL 1 - Βασικές αρχές παρατηρήθηκαν',
  '2': 'TRL 2 - Διατύπωση της έννοιας της τεχνολογίας',
  '3': 'TRL 3 - Πειραματική απόδειξη της ιδέας',
  '4': 'TRL 4 - Τεχνολογία επικυρωμένη στο εργαστήριο',
  '5': 'TRL 5 - Τεχνολογία επικυρωμένη στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '6': 'TRL 6 - Τεχνολογία που επιδεικνύεται στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '7': 'TRL 7 - Επίδειξη πρωτοτύπου συστήματος σε επιχειρησιακό περιβάλλον',
  '8': 'TRL 8 - Το σύστημα ολοκληρώθηκε και πιστοποιήθηκε',
  '9': 'TRL 9 - Πραγματικό σύστημα αποδεδειγμένο σε επιχειρησιακό περιβάλλον (ανταγωνιστική κατασκευή στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής· ή στο διάστημα)',
};

export default class CitizenSupportProposal extends Component {
  constructor(props) {
    super(props);
    this.handleCreate = this.handleCreate.bind(this);
    if (!AuthService.getCurrentUser()) {
      this.props.history.push(`/${(this.props.location.pathname.substring(1, 3) === 'ci' ? 'gr' : this.props.location.pathname.substring(1, 3) )}/login`);
    }

    this.state = {
      userId: AuthService.getCurrentUser() ? AuthService.getCurrentUser().id : null,
      proposalId: this.props.match.params.proposalId,
      proposal: null,
      proposerEmail: null
    };
  }

  componentDidMount() {
    CitizenSupportProposalService.getProposal(this.state.proposalId).then(
      response => {
        this.setState({
          proposal: response.data.message
        });
      },
      error => {
        this.setState({
          proposal:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  handleCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      CitizenSupportProposalService.create(
        this.state.userId,
        this.state.proposalId
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    const proposal = this.state.proposal;
    const proposer = proposal ? proposal[0].user[0].email : null;

    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              <h1 className="govgr-heading-l"><I18n t="main.SupportProposal" /></h1>
              {
                proposal ?
                  Object.keys(proposal).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-xl"><I18n t="main.ProposalTitleandDescription" /></span>
                        <h1 className="govgr-heading-l">{proposal[key].title}</h1>
                        {
                          proposal[key].amountOfFundingRequested ?
                            <>
                              <dl className="govgr-summary-list">
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.description" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].description}</dd>
                                </div>
                                {!proposal[key].newRisLevel && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelOne" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelOne}
                                        {proposal[key].risLevelOne !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelOne]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelTwo" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelTwo}
                                        {proposal[key].risLevelTwo !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelTwo]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelThree" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].risLevelThree}
                                        {proposal[key].risLevelThree !== '' && (<p className="govgr-hint">{RISLEVEL[proposal[key].risLevelThree]}</p>)}
                                      </dd>
                                    </div>
                                  </>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.newRisLevel" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].newRisLevel ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {proposal[key].newRisLevel && (
                                  <div className="govgr-summary-list__row">
                                    <dt className="govgr-summary-list__key"><I18n t="main.newRisLevelDescription" /></dt>
                                    <dd className="govgr-summary-list__value">{proposal[key].newRisLevelDescription}</dd>
                                  </div>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.amountOfFundingRequested" /></dt>
                                  <dd className="govgr-summary-list__value">{parseInt(proposal[key].amountOfFundingRequested).toLocaleString('el-GR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.duration" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].duration}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityStage" /></dt>
                                  <dd className="govgr-summary-list__value">{trl[proposal[key].trl]}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityDocumentation" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].maturityStage}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.risDescription" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].risDescription}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerOne" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerOne}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerTwo" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerTwo}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerThree" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerThree}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerFour" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartnerFour}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartner" /></dt>
                                  <dd className="govgr-summary-list__value">{proposal[key].searchPartner ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {proposal[key].searchPartner && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.searchPartnersAndProfiles" /></dt>
                                      <dd className="govgr-summary-list__value">{proposal[key].searchPartnersAndProfiles}</dd>
                                    </div>
                                  </>
                                )}
                              </dl>
                            </>
                          :
                          ''
                        }
                      </div>;
                    }
                  )
                  :
                  (
                    <div className="govgr-warning-text">
                      <span className="govgr-warning-text__icon">!</span>
                      <strong className="govgr-warning-text__text">
                        <span className="govgr-warning-text__assistive">Loading</span>
                        Loading
                      </strong>
                    </div>
                  )
              }
              {
                proposer ? (<p className="govgr-body"><I18n t="main.Proposer" />: {proposer}</p>) : <Loading />
              }
              <Form
                onSubmit={this.handleCreate}
                ref={c => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="row justify-content-end">
                      <div className="col-md-2">
                        <div className="form-group">
                          <button className="btn btn-warning btn-block"><I18n t="main.Support" /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.message && (
                  <div className="form-group">
                    <div
                      className={
                        this.state.successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {this.state.message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
