import axios from 'axios';
import authHeader from './auth-header';

const API_RESULT_ISSUE_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/result/issue/';

class ResultIssueService {

  getIssue(issue) {
    return axios.get(API_RESULT_ISSUE_URL, {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getIssueCreator(issue) {
    return axios.get(API_RESULT_ISSUE_URL + "creator", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getIssueProposals(issue) {
    return axios.get(API_RESULT_ISSUE_URL + "proposals", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }
}

export default new ResultIssueService();
