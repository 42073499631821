import React, { Component } from "react";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';

const required = value => {
  if (!value) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thisfieldisrequired" />
      </p>
    );
  }
};

const vafm = value => {
  if (value.length < 9 || value.length > 9) {
    return (
      <div className="alert alert-danger" role="alert">
        <I18n t="main.Theafmmustbe9characters" />
      </div>
    );
  }
};

export default class UpdateProfileForRis3 extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.handleUpdateProfileForRis3 = this.handleUpdateProfileForRis3.bind(this);
    this.onChangeAfm = this.onChangeAfm.bind(this);
    this.onChangeOccupation = this.onChangeOccupation.bind(this);
    this.onChangeJobTitle = this.onChangeJobTitle.bind(this);

    this.state = {
      afm: "",
      occupation: "",
      jobTitle: "",
      successful: false,
      message: ""
    };
  }

  onChangeAfm(e) {
    this.setState({
      afm: e.target.value
    });
  }

  onChangeOccupation(e) {
    this.setState({
      occupation: e.target.value
    });
  }

  onChangeJobTitle(e) {
    this.setState({
      jobTitle: e.target.value
    });
  }

  handleUpdateProfileForRis3(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.updateProfileForRis3(
        AuthService.getCurrentUser().id,
        this.state.afm,
        this.state.occupation,
        this.state.jobTitle,
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
          var item = JSON.parse(localStorage.getItem("user"));
          item.afm = this.state.afm;
          item.occupation = this.state.occupation;
          item.jobTitle = this.state.jobTitle;
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(item));
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    return (
      <><Header />
        <div className="govgr-width-container">
            <div className="alert alert-info">
              <strong><I18n t="main.UpdateProfileForRis3" /></strong>
            </div>
        </div>
        <section>
          <div className="govgr-width-container">
            <div className="card mb-4">
              <img
                src="/assets/img/avatar_2x.png"
                alt="profile-img"
                className="d-block mx-auto mb-3 rounded-circle"
                width="96"
                height="96"
              />

              <Form
                onSubmit={this.handleUpdateProfileForRis3}
                ref={c => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>                    
                    <div className="govgr-field">
                      <label className="govgr-label">
                        <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Occupation" /></span>
                        <select
                          name="occupation"
                          className="govgr-select"
                          value={this.state.occupation}
                          onChange={this.onChangeOccupation}
                          validations={[required]}
                        >
                          <option value="">----</option>
                          <option value="business">Επιχείρηση</option>
                          <option value="universityProfessor">Καθηγητής Πανεπιστημίου</option>
                          <option value="instituteResearcher">Ερευνητής Ινστιτούτου</option>
                        </select>
                      </label>
                    </div>
                    
                    <div className="govgr-field">
                      <label className="govgr-label">
                        <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.JobTitle" /></span>
                        <Input
                          type="text"
                          className="govgr-input"
                          name="jobTitle"
                          value={this.state.jobTitle}
                          onChange={this.onChangeJobTitle}
                          validations={[required]}
                        />
                      </label>
                    </div>

                    <div className="govgr-field">
                      <label className="govgr-label">
                        <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Afm" /></span>
                        <Input
                          type="text"
                          className="govgr-input"
                          name="afm"
                          value={this.state.afm}
                          onChange={this.onChangeAfm}
                          validations={[required, vafm]}
                        />
                      </label>
                    </div>

                    <div className="form-group">
                      <button className="btn btn-primary btn-block"><I18n t="main.Update" /></button>
                    </div>
                  </div>
                )}

                {this.state.message && (
                  <div className="form-group">
                    <div
                      className={
                        this.state.successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {this.state.message}<br />
                      {
                        <Route path={this.props.base} render={() =>
                          <Link className="govgr-btn govgr-btn-secondary mt-2" to={"/citizen"}><I18n t="main.Back" /></Link>
                        } />
                      }
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </div>
          </div>
        </section>
        <Footer /></>
    );
  }
}
