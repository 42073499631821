import axios from 'axios';
import authHeader from './auth-header';

const API_PROPOSER_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/proposer/proposal/edit/';

class ProposerProposalCommentEditService {

  update(title, oldTitle, listComments, userId, proposalId) {
    return axios.post(API_PROPOSER_URL + "update", {
      title,
      oldTitle,
      listComments,
      userId,
      proposalId
    },
      { headers: authHeader() }
    );
  }

  updateRis3(title, oldTitle, listComments, risLevelOne, risLevelTwo, risLevelThree, amountOfFundingRequested, maturityStage, risDescription, 
    searchPartnersAndProfiles, 
    description,
    trl,
    duration,
    newRisLevel,
    newRisLevelDescription,
    searchPartner,
    searchPartnerOne,
    searchPartnerTwo,
    searchPartnerThree,
    searchPartnerFour,
    userId, proposalId) {
    return axios.post(API_PROPOSER_URL + "updateRis3", {
      title,
      oldTitle,
      listComments,
      risLevelOne,
      risLevelTwo,
      risLevelThree,
      amountOfFundingRequested,
      maturityStage,
      risDescription,
      searchPartnersAndProfiles,
      description,
      trl,
      duration,
      newRisLevel,
      newRisLevelDescription,
      searchPartner,
      searchPartnerOne,
      searchPartnerTwo,
      searchPartnerThree,
      searchPartnerFour,
      userId,
      proposalId
    },
      { headers: authHeader() }
    );
  }

  getProposal(proposal) {
    return axios.get(API_PROPOSER_URL, {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalProposer(proposal) {
    return axios.get(API_PROPOSER_URL + "proposer", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalComments(proposal) {
    return axios.get(API_PROPOSER_URL + "comments", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

}

export default new ProposerProposalCommentEditService();
