import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import Footer from './footer';
import Header from './header';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <>
        <Header />
        <div className="govgr-width-container">
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-two-thirds">
              {/*<h1 className="govgr-heading-xl"><I18n t="home.title" /></h1>
              <p className="govgr-body"><I18n t="home.podDescription" /></p>*/}
              <h1 className="govgr-heading-xl"><I18n t="home.title" /></h1>
              <h3 className="govgr-heading">
                ΠΕΡΙΦΕΡΕΙΑΚΗ ΣΤΡΑΤΗΓΙΚΗ 
                ΕΞΥΠΝΗΣ ΕΞΕΙΔΙΚΕΥΣΗΣ ΚΑΙΝΟΤΟΜΙΑΣ 
                ΔΥΤΙΚΗΣ ΕΛΛΑΔΑΣ (RIS3-WG)
              </h3>
              <h3 className="govgr-heading">
                ΕΙΣΑΓΩΓΙΚΟ ΚΕΙΜΕΝΟ ΣΕ ΔΙΑΔΙΚΑΣΙΕΣ ΕΠΙΧΕΙΡΗΜΑΤΙΚΗΣ ΑΝΑΚΑΛΥΨΗΣ
              </h3>
              <p className="govgr-body">
                Σκοπός της Διαδικασίας Επιχειρηματικής Ανακάλυψης (ΔΕΑ) του Περιφερειακού Συστήματος Έξυπνης Εξειδίκευσης Καινοτομίας της Δυτικής Ελλάδας 
                (RIS3-WG) είναι η αποτύπωση/τεκμηρίωση της δυναμικής και οι προοπτικές για τους αναφερόμενους στη RIS3WG κλάδους οικονομικής δραστηριότητας.
              </p>
              <p className="govgr-body">
                Οι συμμετέχοντες ενδιαφερόμενοι φορείς μέλη της 4πλης έλικας του οικοσυστήματος καινοτομίας (επιχειρήσεις, Οργανισμοί Έρευνας και Διάδοσης 
                Γνώσης καθώς και κοινωνία πολιτών) καλούνται να συμμετάσχουν στη ΔΕΑ προτείνοντας συγκεκριμένα ερευνητικά/καινοτομικά θέματα/έργα, για τα 
                οποία εκτιμούν ότι θα προωθήσουν την οικονομική δραστηριότητα και την προοπτική ανάπτυξης, του επιμέρους οικονομικού κλάδου/τομέα στη 
                Δυτική Ελλάδα.
              </p>
              <p className="govgr-body">
                Η ΔΕΑ θα διεξαχθεί «σύγχρονα» με τη διοργάνωση θεματικών ημερίδων διαβούλευσης αλλά και «ασύγχρονα» με τη λειτουργία πλατφόρμας υποβολής 
                προτάσεων/ιδεών
              </p>
              <p className="govgr-body">
                Τονίζεται ότι παρόλο του ότι η συμμετοχή των φορέων είναι εθελοντική και δεν αποτελεί προϋπόθεση συμμετοχής σε μελλοντικές δράσεις, με τις 
                υποβαλλόμενες προτάσεις/ιδέες θα αναδειχθούν οι μεσοπρόθεσμοι στόχοι ανάπτυξής του τομέα ενδιαφέροντος και θα διαμορφωθούν σχετικές δράσεις 
                και πολιτικές τόσο σε περιφερειακό αλλά και σε εθνικό επίπεδο.
              </p>
              {!AuthService.getCurrentUser() && (
                <>
                  <Route path={this.props.base} render={() =>
                    <Link to={"/login"} className="govgr-btn govgr-btn-primary govgr-btn-cta">
                      <I18n t="home.Login" />
                      <svg viewBox="0 0 24 24" className="govgr-arrow--right" focusable="false" aria-hidden="true"> <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" /> </svg>
                    </Link>
                  } />
                  <Route path={this.props.base} render={() =>
                    <Link to={"/register"} className="govgr-btn govgr-btn-primary govgr-btn-cta">
                      <I18n t="home.signUp" />
                      <svg viewBox="0 0 24 24" className="govgr-arrow--right" focusable="false" aria-hidden="true"> <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z" /> </svg>
                    </Link>
                  } />
                </>
              )}
            </main>
            <aside className="govgr-grid-column-one-third">
              <img src="assets/img/hero-img.png" className="d-block img-fluid" alt="" width="911" height="735" />
              <img src="assets/img/ris3.png" className="d-block img-fluid" alt="ris3" width="293" height="98" />
            </aside>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
