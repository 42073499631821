import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="govgr-footer" role="contentinfo">
          <div className="govgr-width-container">
            <div className="govgr-footer__navigation">
              <div className="govgr-footer__section">
                <ul className="govgr-footer__list govgr-footer__list--columns-2">
                  <li className="govgr-footer__list-item">
                    <a className="govgr-footer__link govgr-link--no-underline" href="https://diavoulefsys.gr/" target="_blank" rel="noopener noreferrer">https://diavoulefsys.gr/</a>
                  </li>
                </ul>
              </div>
              <div className="govgr-footer__section">
                <ul className="govgr-footer__list ">
                  <li className="govgr-footer__list-item">
                    <a className="govgr-footer__link govgr-link--no-underline" href="mailto:pods.sup@gmail.com">pods.sup@gmail.com</a>
                  </li>
                  <li className="govgr-footer__list-item">
                    <Route path={this.props.base} render={() =>
                      /*<Link className="govgr-footer__link govgr-link--no-underline" to="/help"><I18n t="footer.Help" /></Link>*/
                      <a href="/assets/ΕΓΓΡΑΦΗ ΚΑΙ ΣΥΝΔΕΣΗ ΦΟΡΕΑ_PODS_RIS3.pdf" 
                      className="govgr-footer__link govgr-link--no-underline" target="_blank" rel="noopener noreferrer"><I18n t="footer.Help" /></a>
                    } />
                  </li>
                </ul>
              </div>
            </div>
            <hr className="govgr-footer__section-break_tweak" />
            <div className="govgr-footer__meta">
              <div className="govgr-footer__meta-item govgr-footer__meta-item--grow">
                <div className="govgr-footer__content">
                  <p className="govgr-footer__licence-description">
                    &copy; Copyright <strong><span>Pods</span></strong>. All Rights Reserved
                  </p>
                </div>
              </div>
              {/* <div className="govgr-footer__meta-item">
                <img className="" src="/assets/img/espa.png" alt="espa" width="740" height="52" loading="lazy" /><br />
                ΔΡΑΣΗ ΕΝΙΣΧΥΣΗ ΣΧΕΔΙΩΝ ΕΡΕΥΝΑΣ ΑΝΑΠΤΥΞΗΣ & ΚΑΙΝΟΤΟΜΙΑΣ ΣTON ΤΟΜΕA ΠΡΟΤΕΡΑΙΟΤΗΤΑΣ ΤΗΣ RIS3
                “ΤΕΧΝΟΛΟΓΙΕΣ ΠΛΗΡΟΦΟΡΙΚΗΣ & ΕΠΙΚΟΙΝΩΝΙΩΝ”<br />
                Επιχειρησιακό Πρόγραμμα Δυτική Ελλάδα 2014-2020 <br />
                Με τη συγχρηματοδότηση της Ελλάδας και της Ευρωπαϊκής Ένωσης
              </div> */}
              <div className="govgr-footer__meta-item">
                <img className="" src="/assets/img/espa21-27.png" alt="espa21-27" width="756" height="50" loading="lazy" /><br />
                ΔΡΑΣΕΙΣ ΕΝΙΣΧΥΣΗΣ ΣΧΕΔΙΩΝ ΕΡΕΥΝΑΣ ΑΝΑΠΤΥΞΗΣ & ΚΑΙΝΟΤΟΜΙΑΣ
                ΣΤΟΥΣ ΤΟΜΕΙΣ ΠΡΟΤΕΡΑΙΟΤΗΤΑΣ ΤΗΣ RIS3 ΤΗΣ ΠΕΡΙΦΕΡΕΙΑΣ ΔΥΤΙΚΗΣ ΕΛΛΑΔΑΣ<br />
                Επιχειρησιακό Πρόγραμμα Δυτική Ελλάδα 2014-2020<br />
                Με τη συγχρηματοδότηση της Ελλάδας και της Ευρωπαϊκής Ένωσης
              </div>
            </div>
          </div>
        </footer>
      </>
    )
  }
}
export default Footer;
