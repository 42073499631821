import axios from "axios";

const API_URL = process.env.REACT_APP_BACKEND_URL + '/api/auth/';

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password, /*occupation,*/ street, streetNumber, postalCode, city, roles) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password,
      // occupation,
      street,
      streetNumber,
      postalCode,
      city,
      roles
    });
  }

  updateProfileForRis3(userId, afm, occupation, jobTitle) {
    return axios.post(API_URL + "updateProfileForRis3", {
      userId,
      afm,
      occupation,
      jobTitle
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
}

export default new AuthService();
