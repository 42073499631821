import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./components/login.component";
import Register from "./components/register.component";
import VerifyVerificationToken from "./components/verify-verification-token.component";
import Home from "./components/home.component";
import Profile from "./components/profile.component";
import UpdateProfileForRis3 from "./components/update-profile-for-ris3.component";
import BoardCitizen from "./components/board-citizen.component";
import BoardCitizenSupportProposal from "./components/board-citizen-support-proposal.component";
import BoardCitizenSupportIssue from "./components/board-citizen-support-issue.component";
import BoardProposer from "./components/board-proposer.component";
import BoardProposerIssue from "./components/board-proposer-issue.component";
import BoardProposerIssueMerge from "./components/board-proposer-issue-merge.component";
import BoardProposerIssueMergeRequest from "./components/board-proposer-issue-merge-request.component";
import BoardProposerIssueMergeRequestHistory from "./components/board-proposer-issue-merge-request-history.component";
import BoardIssue from "./components/board-issue.component";
import BoardCitizenIssue from "./components/board-citizen-issue.component";
import BoardProposal from "./components/board-proposal.component";
import BoardProposalComment from "./components/board-proposal-comment.component";
import BoardProposalCommentVerification from "./components/board-proposal-comment-verification.component";
import BoardProposerProposalComment from "./components/board-proposer-proposal-comment.component";
import BoardProposerProposalCommentEdit from "./components/board-proposer-proposal-comment-edit.component";
import BoardAdminProposalComment from "./components/board-admin-proposal-comment.component";
import BoardAdmin from "./components/board-admin.component";
import BoardAdminIssue from "./components/board-admin-issue.component";
import BoardAdminIssueEdit from "./components/board-admin-issue-edit.component";
import BoardAdminIssueConfig from "./components/board-admin-issue-config.component";
import BoardMyComments from "./components/board-my-comments.component";
import BoardMySupports from "./components/board-my-supports.component";
import BoardResultIssue from "./components/board-result-issue.component";
import Help from "./components/help.component";
import ScrollToTop from "./ScrollToTop";

const base = '/:locale(gr|en)?';

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <ScrollToTop />
          <Switch>
            <Route exact path={[`${base}/`, `${base}/home`]} component={(routerProps) => (<Home  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/login`} component={(routerProps) => (<Login  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/register`} component={(routerProps) => (<Register  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/verify/:verificationToken`} component={(routerProps) => (<VerifyVerificationToken  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/profile`} component={(routerProps) => (<Profile  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/update-profile-for-ris3`} component={(routerProps) => (<UpdateProfileForRis3  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/citizen`} component={(routerProps) => (<BoardCitizen  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/citizen/my-comments`} component={(routerProps) => (<BoardMyComments  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/citizen/my-supports`} component={(routerProps) => (<BoardMySupports  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/citizen/support/proposal/:proposalId`} component={(routerProps) => (<BoardCitizenSupportProposal  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/citizen/support/issue/:issueId`} component={(routerProps) => (<BoardCitizenSupportIssue  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposer`} component={(routerProps) => (<BoardProposer  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposer/issue/:issueId`} component={(routerProps) => (<BoardProposerIssue  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposer/issue/merge/:issueId`} component={(routerProps) => (<BoardProposerIssueMerge  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposer/issue/merge/request/:issueId`} component={(routerProps) => (<BoardProposerIssueMergeRequest  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposer/issue/merge/request/history/:issueId`} component={(routerProps) => (<BoardProposerIssueMergeRequestHistory  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/issue/create`} component={(routerProps) => (<BoardIssue  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposal/create/:issueId`} component={(routerProps) => (<BoardProposal  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposal/:proposalId`} component={(routerProps) => (<BoardProposalComment  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/comment_id/:commentId`} component={(routerProps) => (<BoardProposalCommentVerification  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/issue/:issueId`} component={(routerProps) => (<BoardCitizenIssue  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposer/proposal/:proposalId`} component={(routerProps) => (<BoardProposerProposalComment  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/proposer/proposal/edit/:proposalId`} component={(routerProps) => (<BoardProposerProposalCommentEdit  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/admin`} component={(routerProps) => (<BoardAdmin  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/admin/issue/:issueId`} component={(routerProps) => (<BoardAdminIssue  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/admin/issue/edit/:issueId`} component={(routerProps) => (<BoardAdminIssueEdit  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/admin/issue/config/:issueId`} component={(routerProps) => (<BoardAdminIssueConfig  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/admin/proposal/:proposalId`} component={(routerProps) => (<BoardAdminProposalComment  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/result/issue/:issueId`} component={(routerProps) => (<BoardResultIssue  {...routerProps} {...base} />)} />
            <Route exact path={`${base}/help`} component={(routerProps) => (<Help  {...routerProps} {...base} />)} />
            <Route component={(routerProps) => (<Home  {...routerProps} {...base} />)} />
          </Switch>
        </>
      </Router>
    );
  }
}

export default App;
