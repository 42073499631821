import React, { Component } from "react";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';

const required = value => {
  if (!value) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thisfieldisrequired" />
      </p>
    );
  }
};

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      username: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.username, this.state.password).then(
        (data) => {
          const isAdmin = (data.roles.indexOf('ROLE_ADMIN') > -1);
          this.props.history.push("/"+ (this.props.location.pathname.substring(1, 3) === 'lo' ? 'gr' : this.props.location.pathname.substring(1, 3) ) + (isAdmin ? "/admin" : "/citizen"));
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <><Header />
        <section>

          <div className="col-md-12">
            <div className="card card-container mb-4">
              <img
                src="/assets/img/avatar_2x.png"
                alt="profile-img"
                className="d-block mx-auto mb-3 rounded-circle"
                width="96"
                height="96"
              />

              <Form
                onSubmit={this.handleLogin}
                ref={c => {
                  this.form = c;
                }}
              >
                <div className="govgr-field">
                  <label className="govgr-label">
                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Username" /></span>
                    <Input
                      type="text"
                      className="govgr-input"
                      name="username"
                      value={this.state.username}
                      onChange={this.onChangeUsername}
                      validations={[required]}
                    />
                  </label>
                </div>

                <div className="govgr-field">
                  <label className="govgr-label">
                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Password" /></span>
                    <Input
                      type="password"
                      className="govgr-input"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required]}
                    />
                  </label>
                </div>

                <div className="form-group">
                  <button
                    className="btn btn-primary btn-block"
                    disabled={this.state.loading}
                  >
                    {this.state.loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span><I18n t="home.Login" /></span>
                  </button>
                </div>

                {this.state.message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {this.state.message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </div>
          </div>
        </section>
        <Footer /></>
    );
  }
}
