import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton
} from "react-share";
import I18n from '../I18n';
import AdminIssueService from "../services/admin.issue.service";
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';
import Loading from "./loading";
import * as XLSX from 'xlsx';

export default class AdminIssue extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }

    this.state = {
      userId: AuthService.getCurrentUser().id,
      issueId: this.props.match.params.issueId,
      issue: null,
      adminName: null,
      issueProposals: null,
      issueHistory: null,
      socialMediaButton: {
        "&:hover > svg": {
          height: "50px !important",
          width: "50px !important",
        },
      }
    };
  }

  componentDidMount() {
    AdminIssueService.getIssue(this.state.issueId).then(
      response => {
        this.setState({
          issue: response.data.message
        });
      },
      error => {
        this.setState({
          issue:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminIssueService.getIssueCreator(this.state.issueId).then(
      response => {
        this.setState({
          adminName: response.data.message
        });
      },
      error => {
        this.setState({
          adminName:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminIssueService.getIssueProposals(this.state.issueId).then(
      response => {
        this.setState({
          issueProposals: response.data.message
        });
      },
      error => {
        this.setState({
          issueProposals:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminIssueService.getIssueHistory(this.state.issueId).then(
      response => {
        this.setState({
          issueHistory: response.data.message
        });
      },
      error => {
        this.setState({
          issueHistory:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  showResultButton = () => {
    if (this.state.issue) {
      Moment.suppressDeprecationWarnings = true;
      const now = Moment().format('YYYY-MM-DD HH:mm:ss a');
      const minV = this.state.issue ? Moment(this.state.issue[0].voteProposalPeriodFrom, 'YYYY-MM-DD HH:mm:ss a').format() : now;
      const maxV = this.state.issue ? Moment(this.state.issue[0].voteProposalPeriodTo, 'YYYY-MM-DD HH:mm:ss a').format() : now;
      if (Moment().isBetween(minV, maxV, undefined, '[]')) {
        return false
      } else {
        if (Moment().isBefore(maxV)) {
          return false
        } else {
          return true
        }
      }
    }
  }

  exportToExcelFull = () => {
    const trl = {
      '1': 'TRL 1 - Βασικές αρχές παρατηρήθηκαν',
      '2': 'TRL 2 - Διατύπωση της έννοιας της τεχνολογίας',
      '3': 'TRL 3 - Πειραματική απόδειξη της ιδέας',
      '4': 'TRL 4 - Τεχνολογία επικυρωμένη στο εργαστήριο',
      '5': 'TRL 5 - Τεχνολογία επικυρωμένη στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
      '6': 'TRL 6 - Τεχνολογία που επιδεικνύεται στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
      '7': 'TRL 7 - Επίδειξη πρωτοτύπου συστήματος σε επιχειρησιακό περιβάλλον',
      '8': 'TRL 8 - Το σύστημα ολοκληρώθηκε και πιστοποιήθηκε',
      '9': 'TRL 9 - Πραγματικό σύστημα αποδεδειγμένο σε επιχειρησιακό περιβάλλον (ανταγωνιστική κατασκευή στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής· ή στο διάστημα)',
    };

    const data = [
      ['Τίτλος Πρότασης','Περιγραφή','Κωδικός επιπέδου 3 (1η επιλογή)','Κωδικός επιπέδου 3 (2η επιλογή)','Κωδικός επιπέδου 3 (3η επιλογή)',
        'Kατάθεση Νέου Κωδικού','Περιγραφή Νέου Κωδικού','Ύψος Χρηματοδότησης που αιτείται','Χρονική διάρκεια σε μήνες','Στάδιο ωριμότητας',
        'Τεκμηρίωση ωριμότητας','Επιπλέον πληροφορίες','Εταίρος 1','Εταίρος 2','Εταίρος 3','Εταίρος 4','Αναζήτηση εταίρων',
        'Αναζήτηση εταίρων και προφίλ']
    ];
    
    if (this.state.issueProposals && Object.keys(this.state.issueProposals).length > 0) {
      Object.values(this.state.issueProposals).forEach((proposal) => {
        const proposalData = [
          proposal.title,
          proposal.description,
          proposal.risLevelOne,
          proposal.risLevelTwo,
          proposal.risLevelThree,
          proposal.newRisLevel ? 'ΝΑΙ' : 'ΌΧΙ',
          proposal.newRisLevelDescription,
          proposal.amountOfFundingRequested,
          proposal.duration,
          trl[proposal.trl],
          proposal.maturityStage,
          proposal.risDescription,
          proposal.searchPartnerOne,
          proposal.searchPartnerTwo,
          proposal.searchPartnerThree,
          proposal.searchPartnerFour,
          proposal.searchPartner ? 'ΝΑΙ' : 'ΌΧΙ',
          proposal.searchPartnersAndProfiles,
        ];
    
        data.push(proposalData);
      });
    }

    // Create a worksheet
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Create a workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the workbook to a file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };

  render() {
    const issue = this.state.issue;
    const creator = this.state.adminName;
    const issueProposals = this.state.issueProposals;
    const issueHistory = this.state.issueHistory;
    const socialMediaButton = this.state.socialMediaButton;
    Moment.locale('el');
    var thematikesEnotites = new Map();
    thematikesEnotites.set('01', 'ΒΥΚΑ: Υλικά - Κατασκευές και Βιομηχανία');
    thematikesEnotites.set('02', 'ΤΔΠ: Τουρισμός - Πολιτισμός - Δημιουργικές Βιομηχανίες');
    thematikesEnotites.set('03', 'ΑΓΡ: Αγροδιατροφική Αλυσίδα');
    thematikesEnotites.set('04', 'ΠΒΚΟ: Περιβάλλον - Κυκλική Οικονομία');
    thematikesEnotites.set('05', 'ΒΥΦΑ: Βιοεπιστήμες - Υγεία και Φάρμακα');
    thematikesEnotites.set('06', 'ΜΕΑ: Μεταφορές - Εφοδιαστική Αλυσίδα');
    thematikesEnotites.set('07', 'ΑΕΝΕ: Αειφόρος Ενέργεια');
    thematikesEnotites.set('08', 'ΨΤΕ: Ψηφιακές Τεχνολογίες');

    let countProposalsPerThematikiEnotita = {};
    let countProposalsPerDay = {};
    let sumAmountRequestedProposalsPerThematikiEnotita = {};
    
    if (issueProposals) {

      thematikesEnotites.forEach((value, key) => {
        countProposalsPerThematikiEnotita[key] = 0;
        sumAmountRequestedProposalsPerThematikiEnotita[key] = 0;
      });
      
      let uniqueProposalIds = new Set();
      Object.values(issueProposals).forEach((proposal) => {
        const risLevelOneCode = String(proposal.risLevelOne).slice(0, 2);
        const risLevelTwoCode = String(proposal.risLevelTwo).slice(0, 2);
        const risLevelThreeCode = String(proposal.risLevelThree).slice(0, 2);
    
        if (thematikesEnotites.has(risLevelOneCode) && !uniqueProposalIds.has(proposal._id)) {
          countProposalsPerThematikiEnotita[risLevelOneCode]++;
          sumAmountRequestedProposalsPerThematikiEnotita[risLevelOneCode] += Number(proposal.amountOfFundingRequested);
          uniqueProposalIds.add(proposal._id);
        }
    
        if (thematikesEnotites.has(risLevelTwoCode) && !uniqueProposalIds.has(proposal._id)) {
          countProposalsPerThematikiEnotita[risLevelTwoCode]++;
          sumAmountRequestedProposalsPerThematikiEnotita[risLevelTwoCode] += Number(proposal.amountOfFundingRequested);
          uniqueProposalIds.add(proposal._id);
        }
    
        if (thematikesEnotites.has(risLevelThreeCode) && !uniqueProposalIds.has(proposal._id)) {
          countProposalsPerThematikiEnotita[risLevelThreeCode]++;
          sumAmountRequestedProposalsPerThematikiEnotita[risLevelThreeCode] += Number(proposal.amountOfFundingRequested);
          uniqueProposalIds.add(proposal._id);
        }

        // Count proposals per createdAt date
        const proposalCreatedAt = proposal.createdAt; // Assuming createdAt is the property that holds the createdAt date
        var dt = new Date(proposalCreatedAt).toISOString().replace(/T/, ' ').replace(/\..+/, '');
        const createdAt = Moment(dt).format('DD/MM/yyyy')
        if (createdAt) {
          if (!countProposalsPerDay[createdAt]) {
            countProposalsPerDay[createdAt] = 1;
          } else {
            countProposalsPerDay[createdAt]++;
          }
        }
      });
    }
    
    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/admin"><I18n t="main.Issues" /></Link>
                } />
              </li>
            </ol>
          </div>
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              {
                issue ?
                  Object.keys(issue).map(
                    key => {
                      return <h1 className="govgr-heading-l" key={key}>{issue[key].title}</h1>;
                    }
                  )
                  :
                  <Loading />
              }
              <div className="mb-2">
                <h2 className="govgr-heading-s"><I18n t="main.Actions" /></h2>
                <Route path={this.props.base} render={() =>
                  <Link to={issue ? "/admin/issue/edit/" + issue[0]._id : ""}><I18n t="main.EditIssue" /></Link>
                } /><br />
                <Route path={this.props.base} render={() =>
                  <Link to={issue ? "/admin/issue/config/" + issue[0]._id : ""}><I18n t="main.IssueConfig" /></Link>
                } /><br />
                {this.showResultButton() && (<Route path={this.props.base} render={() => <Link to={issue ? "/result/issue/" + issue[0]._id : ""}><I18n t="main.IssueResults" /></Link>} />)}
                <h2 className="govgr-heading-s mt-2"><I18n t="main.Share" /></h2>
                <div className="row">
                  <div className="col-md-1">
                    <EmailShareButton
                      url={issue ? "https://podsweb.dynacomp2.eu/"+ (this.props.location.pathname.substring(1, 3) === 'lo' ? 'gr' : this.props.location.pathname.substring(1, 3) ) +"/citizen/support/issue/" + issue[0]._id : ""}
                      subject={"Pods Support Issue"}
                      body="SuggestionForSupport"
                      className={socialMediaButton}>
                      <EmailIcon size={36} />
                    </EmailShareButton>
                  </div>
                  <div className="col-md-1">
                    <FacebookShareButton
                      url={issue ? "https://podsweb.dynacomp2.eu/"+ (this.props.location.pathname.substring(1, 3) === 'lo' ? 'gr' : this.props.location.pathname.substring(1, 3) ) +"/citizen/support/issue/" + issue[0]._id : ""}
                      quote={"Pods Support Issue"}
                      hashtag="#suggestionForSupport"
                      className={socialMediaButton}>
                      <FacebookIcon size={36} />
                    </FacebookShareButton>
                  </div>
                  <div className="col-md-1">
                    <LinkedinShareButton
                      url={issue ? "https://podsweb.dynacomp2.eu/"+ (this.props.location.pathname.substring(1, 3) === 'lo' ? 'gr' : this.props.location.pathname.substring(1, 3) ) +"/citizen/support/issue/" + issue[0]._id : ""}
                      className={socialMediaButton}>
                      <LinkedinIcon size={36} />
                    </LinkedinShareButton>
                  </div>
                  <div className="col-md-1">
                    <TwitterShareButton
                      url={issue ? "https://podsweb.dynacomp2.eu/"+ (this.props.location.pathname.substring(1, 3) === 'lo' ? 'gr' : this.props.location.pathname.substring(1, 3) ) +"/citizen/support/issue/" + issue[0]._id : ""}
                      title={"Pods Support Issue"}
                      hashtags="#suggestionForSupport"
                      className={socialMediaButton}>
                      <TwitterIcon size={36} />
                    </TwitterShareButton>
                  </div>
                  <div className="col-md-2">
                    <ViberShareButton
                      url={issue ? "https://podsweb.dynacomp2.eu/"+ (this.props.location.pathname.substring(1, 3) === 'lo' ? 'gr' : this.props.location.pathname.substring(1, 3) ) +"/citizen/support/issue/" + issue[0]._id : ""}
                      title={"Pods Support Issue"}
                      className={socialMediaButton}>
                      <ViberIcon size={36} />
                    </ViberShareButton>
                  </div>
                </div>
              </div>
              {
                creator ? (<p className="govgr-body"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
              }
              {
                (issue && issue[0].hasOwnProperty('isForRis3') && issue[0].isForRis3 === true) ?
                  <div className='mb-4'>
                    <button className="govgr-btn govgr-btn-primary" onClick={this.exportToExcelFull}><I18n t="main.exportProposalsToExcel" /></button>
                    <h2 className="govgr-heading-s mt-2 mb-2"><I18n t="main.countProposalsPerThematikiEnotita" /> / <I18n t="main.sumAmountRequestedProposalsPerThematikiEnotita" /></h2>
                    {
                      Object.keys(countProposalsPerThematikiEnotita).length > 0 ?
                        Object.keys(countProposalsPerThematikiEnotita).map(
                          key => {
                            return (
                              <div className="govgr-summary-list__row" key={key}>
                                <dt className="govgr-summary-list__key">
                                  {thematikesEnotites.get(key)}
                                </dt>
                                <dd className="govgr-summary-list__value">
                                  {countProposalsPerThematikiEnotita[key]} / {parseInt(sumAmountRequestedProposalsPerThematikiEnotita[key]).toLocaleString('el-GR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                </dd>
                              </div>
                            );
                          }
                        )
                      : <ul className="timeline mt-4">Loading...</ul>
                    }
                    <h2 className="govgr-heading-s mt-2 mb-2"><I18n t="main.countProposalsPerDay" /></h2>
                    {
                      Object.keys(countProposalsPerDay).length > 0 ?
                        Object.keys(countProposalsPerDay).map(
                          key => {
                            return (
                              <div className="govgr-summary-list__row" key={key}>
                                <dt className="govgr-summary-list__key">
                                  {key}
                                </dt>
                                <dd className="govgr-summary-list__value">
                                  {countProposalsPerDay[key]}
                                </dd>
                              </div>
                            );
                          }
                        )
                      : <ul className="timeline mt-4">Loading...</ul>
                    }
                  </div>
                  :
                  ""
              }
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button className="nav-link active" id="nav-tab1" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true"><I18n t="main.IssueProposals" /></button>
                  <button className="nav-link" id="nav-tab2" data-bs-toggle="tab" data-bs-target="#tab2" type="button" role="tab" aria-controls="tab2" aria-selected="false"><I18n t="main.IssueHistory" /></button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="nav-tab1">
                  <div>
                    {
                      issueProposals ?
                        Object.keys(issueProposals).length > 0 ?
                          Object.keys(issueProposals).map(
                            key => {
                              return (
                                <div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col col-md-9">
                                        <h5 className="card-title">
                                          <Route path={this.props.base} render={() =>
                                            <Link to={"/admin/proposal/" + issueProposals[key]._id}>{issueProposals[key].title}</Link>
                                          } />
                                        </h5>
                                        {
                                          (issue && issue[0].hasOwnProperty('isForRis3') && issue[0].isForRis3 === true && issueProposals[key].searchPartner) ? 
                                            (<I18n t="main.searchPartner" />)
                                          : ''
                                        }
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              );
                            }
                          )
                          : <ul className="timeline mt-4"><I18n t="main.NoavailableIssueProposals" /></ul>
                        : <ul className="timeline mt-4">Loading...</ul>
                    }
                  </div>
                </div>
                <div className="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="nav-tab2">
                  <ul className="timeline mt-4">
                    {
                      issueHistory ?
                        Object.keys(issueHistory).length > 0 ?
                          Object.keys(issueHistory).map(
                            key => {
                              var dt = new Date(issueHistory[key].createdAt).toISOString().replace(/T/, ' ').replace(/\..+/, '');
                              return <li key={key} className="event" data-date={Moment(dt).format('DD/MM/yyyy HH:mm:ss')}>
                                <h3>{issueHistory[key].title}</h3>
                                <p><I18n t="main.PreviousTitle" />: {issueHistory[key].oldTitle}</p>
                              </li>;
                            }
                          )
                          : <I18n t="main.NoavailableIssueHistory" />
                        : "Loading..."
                    }
                  </ul>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Footer /></>
    );
  }
}
