import axios from 'axios';
import authHeader from './auth-header';

const API_CITIZEN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/citizen/issue/';

class VoteService {

  vote(voteType, proposalId, userId) {
    return axios.post(API_CITIZEN_URL + "vote", {
      voteType,
      proposalId,
      userId
    },
      { headers: authHeader() }
    );
  }

  getIssueVoteLimits(userId, proposalId) {
    return axios.get(API_CITIZEN_URL + "vote/limit", {
      headers: authHeader(),
      params: {
        userId,
        proposalId
      },
    }
    );
  }

  getIssueMyVoteLikes(userId, proposalId) {
    return axios.get(API_CITIZEN_URL + "vote/my/likes", {
      headers: authHeader(),
      params: {
        userId,
        proposalId
      },
    }
    );
  }

  getIssueMyVoteDislikes(userId, proposalId) {
    return axios.get(API_CITIZEN_URL + "vote/my/dislikes", {
      headers: authHeader(),
      params: {
        userId,
        proposalId
      },
    }
    );
  }

  getIssueMyVoteNeutrals(userId, proposalId) {
    return axios.get(API_CITIZEN_URL + "vote/my/neutrals", {
      headers: authHeader(),
      params: {
        userId,
        proposalId
      },
    }
    );
  }

  getMyVoteOnProposal(userId, proposalId) {
    return axios.get(API_CITIZEN_URL + "find/my/proposal/vote", {
      headers: authHeader(),
      params: {
        userId,
        proposalId
      },
    }
    );
  }

  voteDelete(voteType, proposalId, userId) {
    return axios.post(API_CITIZEN_URL + "vote/delete", {
      voteType,
      proposalId,
      userId
    },
      { headers: authHeader() }
    );
  }

}

export default new VoteService();
