import Moment from 'moment';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import Select from "react-select";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import I18n from '../I18n';
import AdminIssueConfigService from "../services/admin.issue.config.service";
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

const required = value => {
  if (!value) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thisfieldisrequired" />
      </p>
    );
  }
};

const Tags = [
  { label: "Education", value: 1 },
  { label: "Volunteering", value: 2 },
  { label: "Environment", value: 3 },
  { label: "E-Services", value: 4 },
  { label: "Social Care", value: 5 },
  { label: "Civilization", value: 6 },
  { label: "Tourism", value: 7 },
  { label: "Sport", value: 8 },
];

export default class AdminIssueConfig extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeLike = this.onChangeLike.bind(this);
    this.onChangeDislike = this.onChangeDislike.bind(this);
    this.onChangeNeutral = this.onChangeNeutral.bind(this);
    this.onChangeSupportIssue = this.onChangeSupportIssue.bind(this);
    this.onChangeSupportProposal = this.onChangeSupportProposal.bind(this);
    this.onChangeProposalWorstBorderline = this.onChangeProposalWorstBorderline.bind(this);
    this.onChangeRankFunction = this.onChangeRankFunction.bind(this);
    this.onChangeSupportIssuePeriodFromDate = this.onChangeSupportIssuePeriodFromDate.bind(this);
    this.onChangeSupportIssuePeriodFromTime = this.onChangeSupportIssuePeriodFromTime.bind(this);
    this.onChangeSupportIssuePeriodToDate = this.onChangeSupportIssuePeriodToDate.bind(this);
    this.onChangeSupportIssuePeriodToTime = this.onChangeSupportIssuePeriodToTime.bind(this);
    this.onChangeSupportProposalPeriodFromDate = this.onChangeSupportProposalPeriodFromDate.bind(this);
    this.onChangeSupportProposalPeriodFromTime = this.onChangeSupportProposalPeriodFromTime.bind(this);
    this.onChangeSupportProposalPeriodToDate = this.onChangeSupportProposalPeriodToDate.bind(this);
    this.onChangeSupportProposalPeriodToTime = this.onChangeSupportProposalPeriodToTime.bind(this);
    this.onChangeCommentProposalPeriodFromDate = this.onChangeCommentProposalPeriodFromDate.bind(this);
    this.onChangeCommentProposalPeriodFromTime = this.onChangeCommentProposalPeriodFromTime.bind(this);
    this.onChangeCommentProposalPeriodToDate = this.onChangeCommentProposalPeriodToDate.bind(this);
    this.onChangeCommentProposalPeriodToTime = this.onChangeCommentProposalPeriodToTime.bind(this);
    this.onChangeVoteProposalPeriodFromDate = this.onChangeVoteProposalPeriodFromDate.bind(this);
    this.onChangeVoteProposalPeriodFromTime = this.onChangeVoteProposalPeriodFromTime.bind(this);
    this.onChangeVoteProposalPeriodToDate = this.onChangeVoteProposalPeriodToDate.bind(this);
    this.onChangeVoteProposalPeriodToTime = this.onChangeVoteProposalPeriodToTime.bind(this);
    this.onChangeTags = this.onChangeTags.bind(this);
    this.handleFinalizeDeliberation = this.handleFinalizeDeliberation.bind(this);
    this.handleNextRound = this.handleNextRound.bind(this);

    this.state = {
      like: "",
      dislike: "",
      neutral: "",
      supportIssue: "",
      supportProposal: "",
      proposalWorstBorderline: "",
      rankFunction: "",
      supportIssuePeriodFromDate: "",
      supportIssuePeriodFromTime: "",
      supportIssuePeriodToDate: "",
      supportIssuePeriodToTime: "",
      supportProposalPeriodFromDate: "",
      supportProposalPeriodFromTime: "",
      supportProposalPeriodToDate: "",
      supportProposalPeriodToTime: "",
      commentProposalPeriodFromDate: "",
      commentProposalPeriodFromTime: "",
      commentProposalPeriodToDate: "",
      commentProposalPeriodToTime: "",
      voteProposalPeriodFromDate: "",
      voteProposalPeriodFromTime: "",
      voteProposalPeriodToDate: "",
      voteProposalPeriodToTime: "",
      tags: [],
      userId: AuthService.getCurrentUser().id,
      issueId: this.props.match.params.issueId,
      issue: null,
      creator: null,
      successful: false,
      message: ""
    };
  }

  componentDidMount() {
    AdminIssueConfigService.getIssue(this.state.issueId).then(
      response => {
        const issueResponse = response.data.message;
        const supportIssuePeriodFromDateTime = issueResponse[0].supportIssuePeriodFrom ? new Date(issueResponse[0].supportIssuePeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        const supportIssuePeriodToDateTime = issueResponse[0].supportIssuePeriodTo ? new Date(issueResponse[0].supportIssuePeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        const supportProposalPeriodFromDateTime = issueResponse[0].supportProposalPeriodFrom ? new Date(issueResponse[0].supportProposalPeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        const supportProposalPeriodToDateTime = issueResponse[0].supportProposalPeriodTo ? new Date(issueResponse[0].supportProposalPeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        const commentProposalPeriodFromDateTime = issueResponse[0].commentProposalPeriodFrom ? new Date(issueResponse[0].commentProposalPeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        const commentProposalPeriodToDateTime = issueResponse[0].commentProposalPeriodTo ? new Date(issueResponse[0].commentProposalPeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        const voteProposalPeriodFromDateTime = issueResponse[0].voteProposalPeriodFrom ? new Date(issueResponse[0].voteProposalPeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        const voteProposalPeriodToDateTime = issueResponse[0].voteProposalPeriodTo ? new Date(issueResponse[0].voteProposalPeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : "";
        this.setState({
          issue: issueResponse,
          tags: issueResponse[0].tags,
          supportIssuePeriodFromDate: supportIssuePeriodFromDateTime !== "" ? Moment(supportIssuePeriodFromDateTime).format('yyyy-MM-DD') : "",
          supportIssuePeriodFromTime: supportIssuePeriodFromDateTime !== "" ? Moment(supportIssuePeriodFromDateTime).format('HH:mm') : "",
          supportIssuePeriodToDate: supportIssuePeriodToDateTime !== "" ? Moment(supportIssuePeriodToDateTime).format('yyyy-MM-DD') : "",
          supportIssuePeriodToTime: supportIssuePeriodToDateTime !== "" ? Moment(supportIssuePeriodToDateTime).format('HH:mm') : "",
          supportProposalPeriodFromDate: supportProposalPeriodFromDateTime !== "" ? Moment(supportProposalPeriodFromDateTime).format('yyyy-MM-DD') : "",
          supportProposalPeriodFromTime: supportProposalPeriodFromDateTime !== "" ? Moment(supportProposalPeriodFromDateTime).format('HH:mm') : "",
          supportProposalPeriodToDate: supportProposalPeriodToDateTime !== "" ? Moment(supportProposalPeriodToDateTime).format('yyyy-MM-DD') : "",
          supportProposalPeriodToTime: supportProposalPeriodToDateTime !== "" ? Moment(supportProposalPeriodToDateTime).format('HH:mm') : "",
          commentProposalPeriodFromDate: commentProposalPeriodFromDateTime !== "" ? Moment(commentProposalPeriodFromDateTime).format('yyyy-MM-DD') : "",
          commentProposalPeriodFromTime: commentProposalPeriodFromDateTime !== "" ? Moment(commentProposalPeriodFromDateTime).format('HH:mm') : "",
          commentProposalPeriodToDate: commentProposalPeriodToDateTime !== "" ? Moment(commentProposalPeriodToDateTime).format('yyyy-MM-DD') : "",
          commentProposalPeriodToTime: commentProposalPeriodToDateTime !== "" ? Moment(commentProposalPeriodToDateTime).format('HH:mm') : "",
          voteProposalPeriodFromDate: voteProposalPeriodFromDateTime !== "" ? Moment(voteProposalPeriodFromDateTime).format('yyyy-MM-DD') : "",
          voteProposalPeriodFromTime: voteProposalPeriodFromDateTime !== "" ? Moment(voteProposalPeriodFromDateTime).format('HH:mm') : "",
          voteProposalPeriodToDate: voteProposalPeriodToDateTime !== "" ? Moment(voteProposalPeriodToDateTime).format('yyyy-MM-DD') : "",
          voteProposalPeriodToTime: voteProposalPeriodToDateTime !== "" ? Moment(voteProposalPeriodToDateTime).format('HH:mm') : "",
        });
      },
      error => {
        this.setState({
          issue:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminIssueConfigService.getCreator(this.state.issueId).then(
      response => {
        this.setState({
          creator: response.data.message
        });
      },
      error => {
        this.setState({
          creator:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  onChangeLike(e) {
    this.setState({
      like: e.target.value
    });
  }

  onChangeDislike(e) {
    this.setState({
      dislike: e.target.value
    });
  }

  onChangeNeutral(e) {
    this.setState({
      neutral: e.target.value
    });
  }

  onChangeSupportIssue(e) {
    this.setState({
      supportIssue: e.target.value
    });
  }

  onChangeSupportProposal(e) {
    this.setState({
      supportProposal: e.target.value
    });
  }

  onChangeProposalWorstBorderline(e) {
    this.setState({
      proposalWorstBorderline: e.target.value
    });
  }

  onChangeRankFunction(e) {
    this.setState({
      rankFunction: e.target.value
    });
  }

  onChangeSupportIssuePeriodFromDate(e) {
    this.setState({
      supportIssuePeriodFromDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeSupportIssuePeriodFromTime(e) {
    this.setState({
      supportIssuePeriodFromTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeSupportIssuePeriodToDate(e) {
    this.setState({
      supportIssuePeriodToDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeSupportIssuePeriodToTime(e) {
    this.setState({
      supportIssuePeriodToTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeSupportProposalPeriodFromDate(e) {
    this.setState({
      supportProposalPeriodFromDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeSupportProposalPeriodFromTime(e) {
    this.setState({
      supportProposalPeriodFromTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeSupportProposalPeriodToDate(e) {
    this.setState({
      supportProposalPeriodToDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeSupportProposalPeriodToTime(e) {
    this.setState({
      supportProposalPeriodToTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeCommentProposalPeriodFromDate(e) {
    this.setState({
      commentProposalPeriodFromDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeCommentProposalPeriodFromTime(e) {
    this.setState({
      commentProposalPeriodFromTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeCommentProposalPeriodToDate(e) {
    this.setState({
      commentProposalPeriodToDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeCommentProposalPeriodToTime(e) {
    this.setState({
      commentProposalPeriodToTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeVoteProposalPeriodFromDate(e) {
    this.setState({
      voteProposalPeriodFromDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeVoteProposalPeriodFromTime(e) {
    this.setState({
      voteProposalPeriodFromTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeVoteProposalPeriodToDate(e) {
    this.setState({
      voteProposalPeriodToDate: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeVoteProposalPeriodToTime(e) {
    this.setState({
      voteProposalPeriodToTime: e.target.value === "" ? null : e.target.value
    });
  }

  onChangeTags(e) {
    let stateIssue = [...this.state.issue];
    stateIssue[0] = { ...stateIssue[0], tags: e };

    this.setState({
      tags: e,
      issue: stateIssue,
    });
  }

  handleUpdate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AdminIssueConfigService.update(
        this.state.like === "" ? this.state.issue[0].like : this.state.like,
        this.state.dislike === "" ? this.state.issue[0].dislike : this.state.dislike,
        this.state.neutral === "" ? this.state.issue[0].neutral : this.state.neutral,
        this.state.supportIssue === "" ? this.state.issue[0].supportIssue : this.state.supportIssue,
        this.state.supportProposal === "" ? this.state.issue[0].supportProposal : this.state.supportProposal,
        this.state.proposalWorstBorderline === "" ? this.state.issue[0].proposalWorstBorderline : this.state.proposalWorstBorderline,
        this.state.rankFunction === "" ? this.state.issue[0].rankFunction : this.state.rankFunction,
        this.state.supportIssuePeriodFromDate === "" || this.state.supportIssuePeriodFromTime === "" ? this.state.issue[0].supportIssuePeriodFrom : this.state.supportIssuePeriodFromDate + "T" + this.state.supportIssuePeriodFromTime + ":59.000+00:00",
        this.state.supportIssuePeriodToDate === "" || this.state.supportIssuePeriodToTime === "" ? this.state.issue[0].supportIssuePeriodTo : this.state.supportIssuePeriodToDate + "T" + this.state.supportIssuePeriodToTime + ":59.000+00:00",
        this.state.supportProposalPeriodFromDate === "" || this.state.supportProposalPeriodFromTime === "" ? this.state.issue[0].supportProposalPeriodFrom : this.state.supportProposalPeriodFromDate + "T" + this.state.supportProposalPeriodFromTime + ":59.000+00:00",
        this.state.supportProposalPeriodToDate === "" || this.state.supportProposalPeriodToTime === "" ? this.state.issue[0].supportProposalPeriodTo : this.state.supportProposalPeriodToDate + "T" + this.state.supportProposalPeriodToTime + ":59.000+00:00",
        this.state.commentProposalPeriodFromDate === "" || this.state.commentProposalPeriodFromTime === "" ? this.state.issue[0].commentProposalPeriodFrom : this.state.commentProposalPeriodFromDate + "T" + this.state.commentProposalPeriodFromTime + ":59.000+00:00",
        this.state.commentProposalPeriodToDate === "" || this.state.commentProposalPeriodToTime === "" ? this.state.issue[0].commentProposalPeriodTo : this.state.commentProposalPeriodToDate + "T" + this.state.commentProposalPeriodToTime + ":59.000+00:00",
        this.state.voteProposalPeriodFromDate === "" || this.state.voteProposalPeriodFromTime === "" ? this.state.issue[0].voteProposalPeriodFrom : this.state.voteProposalPeriodFromDate + "T" + this.state.voteProposalPeriodFromTime + ":59.000+00:00",
        this.state.voteProposalPeriodToDate === "" || this.state.voteProposalPeriodToTime === "" ? this.state.issue[0].voteProposalPeriodTo : this.state.voteProposalPeriodToDate + "T" + this.state.voteProposalPeriodToTime + ":59.000+00:00",
        this.state.tags,
        this.state.userId,
        this.state.issueId
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  handleFinalizeDeliberation(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });
    AdminIssueConfigService.finalizeDeliberation(
      this.state.userId,
      this.state.issueId
    ).then(
      response => {
        this.setState({
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          successful: false,
          message: resMessage
        });
      }
    );
  }

  handleNextRound(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });
    AdminIssueConfigService.nextRound(
      this.state.userId,
      this.state.issueId
    ).then(
      response => {
        this.setState({
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          successful: false,
          message: resMessage
        });
      }
    );
  }

  render() {
    const issue = this.state.issue;
    const creator = this.state.creator;

    const {
      supportIssuePeriodFromDate,
      supportIssuePeriodFromTime,
      supportIssuePeriodToDate,
      supportIssuePeriodToTime,
      supportProposalPeriodFromDate,
      supportProposalPeriodFromTime,
      supportProposalPeriodToDate,
      supportProposalPeriodToTime,
      commentProposalPeriodFromDate,
      commentProposalPeriodFromTime,
      commentProposalPeriodToDate,
      commentProposalPeriodToTime,
      voteProposalPeriodFromDate,
      voteProposalPeriodFromTime,
      voteProposalPeriodToDate,
      voteProposalPeriodToTime
    } = this.state;

    return (
      <>
        <Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/admin"><I18n t="main.Issues" /></Link>
                } />
              </li>
              <li className="govgr-breadcrumbs__list-item">
                {
                  issue ?
                    Object.keys(issue).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/admin/issue/" + issue[key]._id}>{issue[key].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
            </ol>
          </div>
          {!this.state.successful && (
            <>
              {
                issue ?
                  Object.keys(issue).map(
                    key => {
                      var supportIssuePeriodFromDateTime = issue[key].supportIssuePeriodFrom ? new Date(issue[key].supportIssuePeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      var supportIssuePeriodToDateTime = issue[key].supportIssuePeriodTo ? new Date(issue[key].supportIssuePeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      var supportProposalPeriodFromDateTime = issue[key].supportProposalPeriodFrom ? new Date(issue[key].supportProposalPeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      var supportProposalPeriodToDateTime = issue[key].supportProposalPeriodTo ? new Date(issue[key].supportProposalPeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      var commentProposalPeriodFromDateTime = issue[key].commentProposalPeriodFrom ? new Date(issue[key].commentProposalPeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      var commentProposalPeriodToDateTime = issue[key].commentProposalPeriodTo ? new Date(issue[key].commentProposalPeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      var voteProposalPeriodFromDateTime = issue[key].voteProposalPeriodFrom ? new Date(issue[key].voteProposalPeriodFrom).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      var voteProposalPeriodToDateTime = issue[key].voteProposalPeriodTo ? new Date(issue[key].voteProposalPeriodTo).toISOString().replace(/T/, ' ').replace(/\..+/, '') : null;
                      return <div key={key}>
                        <h1 className="govgr-heading-l"><I18n t="main.Config" />: {issue[key].title}</h1>
                        {
                          creator ? (<p className="govgr-hint"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
                        }
                        <Form
                          onSubmit={this.handleUpdate}
                          ref={c => {
                            this.form = c;
                          }}
                        >
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.MaxLikes" /></span>
                              <Input
                                type="number"
                                className="govgr-input"
                                name="like"
                                value={String(issue[key].like)}
                                onChange={this.onChangeLike}
                                validations={[required]}
                              />
                            </label>
                          </div>
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.MaxDislikes" /></span>
                              <Input
                                type="number"
                                className="govgr-input"
                                name="dislike"
                                value={String(issue[key].dislike)}
                                onChange={this.onChangeDislike}
                                validations={[required]}
                              />
                            </label>
                          </div>
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.MaxNeutral" /></span>
                              <Input
                                type="number"
                                className="govgr-input"
                                name="neutral"
                                value={String(issue[key].neutral)}
                                onChange={this.onChangeNeutral}
                                validations={[required]}
                              />
                            </label>
                          </div>
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Minissuesupports" /></span>
                              <Input
                                type="number"
                                className="govgr-input"
                                name="supportIssue"
                                value={String(issue[key].supportIssue)}
                                onChange={this.onChangeSupportIssue}
                                validations={[required]}
                              />
                            </label>
                          </div>
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Minproposalsupports" /></span>
                              <Input
                                type="number"
                                className="govgr-input"
                                name="supportProposal"
                                value={String(issue[key].supportProposal)}
                                onChange={this.onChangeSupportProposal}
                                validations={[required]}
                              />
                            </label>
                          </div>
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Worstproposalborderline" /></span>
                              <p className="govgr-hint"><I18n t="main.eg3lastrankedproposalnotcontinuetootherphases" /></p>
                              <Input
                                type="number"
                                className="govgr-input"
                                name="proposalWorstBorderline"
                                value={String(issue[key].proposalWorstBorderline)}
                                onChange={this.onChangeProposalWorstBorderline}
                                validations={[required]}
                              />
                            </label>
                          </div>
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Rankfunction" /></span>
                              <p className="govgr-hint"><I18n t="main.use" /> <b>l</b> <I18n t="main.forlike" />, <b>d</b> <I18n t="main.fordislike" /> <I18n t="main.and" /> <b>n</b> <I18n t="main.forneutral" />, <I18n t="main.eg" /> l+d+(n/2)</p>
                              <Input
                                type="text"
                                className="govgr-input"
                                name="rankFunction"
                                value={issue[key].rankFunction}
                                onChange={this.onChangeRankFunction}
                                validations={[required]}
                              />
                            </label>
                          </div>
                          <div className="row">
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportissueperiodfromdate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="supportIssuePeriodFromDate"
                                        value={supportIssuePeriodFromDateTime ? Moment(supportIssuePeriodFromDateTime).format('yyyy-MM-DD') : supportIssuePeriodFromDate !== "" ? supportIssuePeriodFromDate : ""}
                                        max={supportIssuePeriodToDate !== "" ? supportIssuePeriodToDate : ""}
                                        onChange={this.onChangeSupportIssuePeriodFromDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportissueperiodfromtime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="supportIssuePeriodFromTime"
                                        value={supportIssuePeriodFromDateTime ? Moment(supportIssuePeriodFromDateTime).format('HH:mm') : supportIssuePeriodFromTime !== "" ? supportIssuePeriodFromTime : ""}
                                        max={supportIssuePeriodToTime !== "" ? supportIssuePeriodToTime : ""}
                                        onChange={this.onChangeSupportIssuePeriodFromTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportissueperiodtodate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="supportIssuePeriodToDate"
                                        value={supportIssuePeriodToDateTime ? Moment(supportIssuePeriodToDateTime).format('yyyy-MM-DD') : supportIssuePeriodToDate !== "" ? supportIssuePeriodToDate : ""}
                                        min={supportIssuePeriodFromDate !== "" ? supportIssuePeriodFromDate : ""}
                                        onChange={this.onChangeSupportIssuePeriodToDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportissueperiodtotime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="supportIssuePeriodToTime"
                                        value={supportIssuePeriodToDateTime ? Moment(supportIssuePeriodToDateTime).format('HH:mm') : supportIssuePeriodToTime !== "" ? supportIssuePeriodToTime : ""}
                                        min={supportIssuePeriodFromTime !== "" ? supportIssuePeriodFromTime : ""}
                                        onChange={this.onChangeSupportIssuePeriodToTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportproposalperiodfromdate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="supportProposalPeriodFromDate"
                                        value={supportProposalPeriodFromDateTime ? Moment(supportProposalPeriodFromDateTime).format('yyyy-MM-DD') : supportProposalPeriodFromDate !== "" ? supportProposalPeriodFromDate : ""}
                                        max={supportProposalPeriodToDate !== "" ? supportProposalPeriodToDate : ""}
                                        onChange={this.onChangeSupportProposalPeriodFromDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportproposalperiodfromtime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="supportProposalPeriodFromTime"
                                        value={supportProposalPeriodFromDateTime ? Moment(supportProposalPeriodFromDateTime).format('HH:mm') : supportProposalPeriodFromTime !== "" ? supportProposalPeriodFromTime : ""}
                                        max={supportProposalPeriodToTime !== "" ? supportProposalPeriodToTime : ""}
                                        onChange={this.onChangeSupportProposalPeriodFromTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportproposalperiodtodate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="supportProposalPeriodToDate"
                                        value={supportProposalPeriodToDateTime ? Moment(supportProposalPeriodToDateTime).format('yyyy-MM-DD') : supportProposalPeriodToDate !== "" ? supportProposalPeriodToDate : ""}
                                        min={supportProposalPeriodFromDate !== "" ? supportProposalPeriodFromDate : ""}
                                        onChange={this.onChangeSupportProposalPeriodToDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Supportproposalperiodtotime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="supportProposalPeriodToTime"
                                        value={supportProposalPeriodToDateTime ? Moment(supportProposalPeriodToDateTime).format('HH:mm') : supportProposalPeriodToTime !== "" ? supportProposalPeriodToTime : ""}
                                        min={supportProposalPeriodFromTime !== "" ? supportProposalPeriodFromTime : ""}
                                        onChange={this.onChangeSupportProposalPeriodToTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Commentproposalperiodfromdate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="commentProposalPeriodFromDate"
                                        value={commentProposalPeriodFromDateTime ? Moment(commentProposalPeriodFromDateTime).format('yyyy-MM-DD') : commentProposalPeriodFromDate !== "" ? commentProposalPeriodFromDate : ""}
                                        max={commentProposalPeriodToDate !== "" ? commentProposalPeriodToDate : ""}
                                        onChange={this.onChangeCommentProposalPeriodFromDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Commentproposalperiodfromtime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="commentProposalPeriodFromTime"
                                        value={commentProposalPeriodFromDateTime ? Moment(commentProposalPeriodFromDateTime).format('HH:mm') : commentProposalPeriodFromTime !== "" ? commentProposalPeriodFromTime : ""}
                                        max={commentProposalPeriodToTime !== "" ? commentProposalPeriodToTime : ""}
                                        onChange={this.onChangeCommentProposalPeriodFromTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Commentproposalperiodtodate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="commentProposalPeriodToDate"
                                        value={commentProposalPeriodToDateTime ? Moment(commentProposalPeriodToDateTime).format('yyyy-MM-DD') : commentProposalPeriodToDate !== "" ? commentProposalPeriodToDate : ""}
                                        min={commentProposalPeriodFromDate !== "" ? commentProposalPeriodFromDate : ""}
                                        onChange={this.onChangeCommentProposalPeriodToDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Commentproposalperiodtotime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="commentProposalPeriodToTime"
                                        value={commentProposalPeriodToDateTime ? Moment(commentProposalPeriodToDateTime).format('HH:mm') : commentProposalPeriodToTime !== "" ? commentProposalPeriodToTime : ""}
                                        min={commentProposalPeriodFromTime !== "" ? commentProposalPeriodFromTime : ""}
                                        onChange={this.onChangeCommentProposalPeriodToTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Voteproposalperiodfromdate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="voteProposalPeriodFromDate"
                                        value={voteProposalPeriodFromDateTime ? Moment(voteProposalPeriodFromDateTime).format('yyyy-MM-DD') : voteProposalPeriodFromDate !== "" ? voteProposalPeriodFromDate : ""}
                                        max={voteProposalPeriodToDate !== "" ? voteProposalPeriodToDate : ""}
                                        onChange={this.onChangeVoteProposalPeriodFromDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Voteproposalperiodfromtime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="voteProposalPeriodFromTime"
                                        value={voteProposalPeriodFromDateTime ? Moment(voteProposalPeriodFromDateTime).format('HH:mm') : voteProposalPeriodFromTime !== "" ? voteProposalPeriodFromTime : ""}
                                        max={voteProposalPeriodToTime !== "" ? voteProposalPeriodToTime : ""}
                                        onChange={this.onChangeVoteProposalPeriodFromTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col col-md-6">
                              <div className="row">
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Voteproposalperiodtodate" /></span>
                                      <Input
                                        type="date"
                                        className="govgr-input"
                                        name="voteProposalPeriodToDate"
                                        value={voteProposalPeriodToDateTime ? Moment(voteProposalPeriodToDateTime).format('yyyy-MM-DD') : voteProposalPeriodToDate !== "" ? voteProposalPeriodToDate : ""}
                                        min={voteProposalPeriodFromDate !== "" ? voteProposalPeriodFromDate : ""}
                                        onChange={this.onChangeVoteProposalPeriodToDate}
                                      />
                                    </label>
                                  </div>
                                </div>
                                <div className="col col-md-6">
                                  <div className="govgr-field">
                                    <label className="govgr-label">
                                      <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Voteproposalperiodtotime" /></span>
                                      <Input
                                        type="time"
                                        className="govgr-input"
                                        name="voteProposalPeriodToTime"
                                        value={voteProposalPeriodToDateTime ? Moment(voteProposalPeriodToDateTime).format('HH:mm') : voteProposalPeriodToTime !== "" ? voteProposalPeriodToTime : ""}
                                        min={voteProposalPeriodFromTime !== "" ? voteProposalPeriodFromTime : ""}
                                        onChange={this.onChangeVoteProposalPeriodToTime}
                                      />
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col col-md-12">
                              <div className="govgr-field">
                                <label className="govgr-label">
                                  <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.Tags" /></span>
                                  <Select
                                    className="govgr-select"
                                    options={Tags}
                                    isMulti
                                    name="tags"
                                    value={issue[key].tags}
                                    onChange={this.onChangeTags}
                                  />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <button className="btn btn-primary btn-block"><I18n t="main.SaveIssueConfigs" /></button>
                          </div>

                          <h4><I18n t="main.HandleDeliberation" /></h4>
                          <div className="alert alert-info">
                            <p> <I18n t="main.Thisissueis" /> <strong>{issue && issue[0].finalizeDeliberation === 0 ? <I18n t="main.open" /> : <I18n t="main.closed" />} </strong>
                              <I18n t="main.andcurrentRoundis" /> <strong>{issue && issue[0].nextRound}</strong>.</p>
                            {issue && issue[0].finalizeDeliberation === 1 && (<button className="btn btn-info" onClick={this.handleFinalizeDeliberation}>
                              <I18n t="main.OpenFinalizedDeliberation" />
                            </button>)}
                            {issue && issue[0].finalizeDeliberation === 0 && (<button className="btn btn-success" onClick={this.handleFinalizeDeliberation}>
                              <I18n t="main.FinalizeDeliberation" />
                            </button>)}
                            {issue && issue[0].finalizeDeliberation === 0 && (<button className="btn btn-warning ml-2" onClick={this.handleNextRound}>
                              <I18n t="main.CreateNextRound" />
                            </button>)}
                          </div>

                          <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>
                      </div>;
                    }
                  )
                  :
                  <Loading />
              }
            </>
          )}

          {this.state.message && (
            <div className="form-group">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}<br />
                {
                  issue ?
                    Object.keys(issue).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-btn govgr-btn-secondary mt-2" to={"/admin/issue/" + issue[key]._id}><I18n t="main.Back" /></Link>
                        } />;
                      }
                    )
                    :
                    <Loading />
                }
              </div>
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}
