import axios from 'axios';
import authHeader from './auth-header';

const API_ADMIN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/admin/issue/';

class AdminIssueService {

  getIssue(issue) {
    return axios.get(API_ADMIN_URL, {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getIssueCreator(issue) {
    return axios.get(API_ADMIN_URL + "creator", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getIssueProposals(issue) {
    return axios.get(API_ADMIN_URL + "proposals", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getIssueHistory(issue) {
    return axios.get(API_ADMIN_URL + "history", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

}

export default new AdminIssueService();
