import React, { Component } from "react";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposerIssueMergeService from "../services/proposer.issue.merge.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class ProposerIssueMergeRequest extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.onAcceptMergeRequest = this.onAcceptMergeRequest.bind(this);
    this.onRejectMergeRequest = this.onRejectMergeRequest.bind(this);

    this.state = {
      userId: AuthService.getCurrentUser().id,
      issueId: this.props.match.params.issueId,
      issue: null,
      adminName: null,
      issueProposals: null,
      myProposal: null,
      proposalsOfOtherProposers: null,
      mergeRequest: null,
      successful: false,
      message: ""
    };
  }

  componentDidMount() {
    ProposerIssueMergeService.getIssue(this.state.issueId).then(
      response => {
        this.setState({
          issue: response.data.message
        });
      },
      error => {
        this.setState({
          issue:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerIssueMergeService.getIssueCreator(this.state.issueId).then(
      response => {
        this.setState({
          adminName: response.data.message
        });
      },
      error => {
        this.setState({
          adminName:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerIssueMergeService.getIssueProposals(this.state.issueId).then(
      response => {
        this.setState({
          issueProposals: response.data.message
        });
      },
      error => {
        this.setState({
          issueProposals:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposerIssueMergeService.getMergeRequestProposers(this.state.userId, this.state.issueId).then(
      response => {
        this.setState({
          mergeRequest: response.data.message
        });
      },
      error => {
        this.setState({
          mergeRequest:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  onAcceptMergeRequest(mergeRequestId) {
    this.setState({
      message: "",
      successful: false
    });

    ProposerIssueMergeService.acceptMergeRequest(
      mergeRequestId
    ).then(
      response => {
        this.setState({
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          successful: false,
          message: resMessage
        });
      }
    );
  }

  onRejectMergeRequest(mergeRequestId) {
    this.setState({
      message: "",
      successful: false
    });

    ProposerIssueMergeService.rejectMergeRequest(
      mergeRequestId
    ).then(
      response => {
        this.setState({
          message: response.data.message,
          successful: true
        });
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          successful: false,
          message: resMessage
        });
      }
    );
  }

  proposalPerProposer = () => {
    if (this.state.issueProposals) {
      var issueProposals = this.state.issueProposals
      let myIssueProposal = [];
      Object.keys(issueProposals).filter((proposal) => issueProposals[proposal].user[0] === this.state.userId).map((proposal) =>
        myIssueProposal.push(issueProposals[proposal])
      );
      let issueProposalsOfOtherProposers = [];
      Object.keys(issueProposals).filter((proposal) => issueProposals[proposal].user[0] !== this.state.userId).map((proposal) =>
        issueProposalsOfOtherProposers.push(issueProposals[proposal])
      );
      return [
        {
          "myProposal": myIssueProposal,
          "proposalsOfOtherProposers": issueProposalsOfOtherProposers,
        }
      ]
    }
  }

  render() {
    const issue = this.state.issue;
    const creator = this.state.adminName;
    const proposalPerProposer = this.proposalPerProposer();
    const myProposal = proposalPerProposer ? proposalPerProposer[0]['myProposal'] : null;
    const mergeRequest = this.state.mergeRequest ? this.state.mergeRequest.length > 0 ? this.state.mergeRequest : null : null;
    return (
      <><Header />
        <h3><I18n t="main.IssueInfo" /></h3>
        <div>{
          issue ?
            Object.keys(issue).map(
              key => {
                return <span key={key}><I18n t="main.IssueTitle" />: {issue[key].title}</span>;
              }
            )
            :
            <Loading />
        }
        </div>
        <div>
          {creator ? <I18n t="main.Creator" /> : <Loading />}
          {creator ? ": " + creator : <Loading />}
        </div>
        <hr />
        <h3><I18n t="main.MyProposal" /></h3>
        <div>{
          myProposal ?
            Object.keys(myProposal).map(
              key => {
                return <span key={key}>{myProposal[key].title}</span>;
              }
            )
            :
            <Loading />
        }
        </div>
        {mergeRequest && (<div>
          <hr />
          <h3><I18n t="main.Detailsofmyproposalmergerequests" />:</h3>
          {!this.state.successful && (<div>
            {
              mergeRequest ?
                Object.keys(mergeRequest).map(
                  key => {
                    return <div className="card" key={key}>
                      <div className="card-body">
                        <p className="card-title"><I18n t="main.Mergemyproposalwith" />:</p>
                        <ul>
                          {
                            mergeRequest[key].creatorProposal ?
                              Object.keys(mergeRequest[key].creatorProposal).map(
                                keyP => {
                                  return <li key={keyP}>{mergeRequest[key].creatorProposal[keyP].title}</li>;
                                }
                              )
                              :
                              "Loading..."
                          }
                        </ul>
                        <p className="card-title"><I18n t="main.frommergerequestcreator" />:</p>
                        <ul>
                          {
                            mergeRequest[key].creator ?
                              Object.keys(mergeRequest[key].creator).map(
                                keyC => {
                                  return <li key={keyC}>{mergeRequest[key].creator[keyC].username} (e-mail: {mergeRequest[key].creator[keyC].email})</li>;
                                }
                              )
                              :
                              "Loading..."
                          }
                        </ul>
                        <p className="card-title"><I18n t="main.mergerequestProposalswithProposers" />:</p>
                        <ul>
                          {
                            mergeRequest[key].mergeRequestProposals ?
                              Object.keys(mergeRequest[key].mergeRequestProposals).map(
                                keyMrp => {
                                  return <li key={keyMrp}>{mergeRequest[key].mergeRequestProposals[keyMrp].title} (
                                    {
                                      Object.keys(mergeRequest[key].mergeRequestProposals[keyMrp].user).map(
                                        keyMrpp => {
                                          return <span key={keyMrpp}>{mergeRequest[key].mergeRequestProposals[keyMrp].user[keyMrpp].username} (e-mail: {mergeRequest[key].mergeRequestProposals[keyMrp].user[keyMrpp].email})</span>;
                                        }
                                      )
                                    }
                                    )</li>;
                                }
                              )
                              :
                              "Loading..."
                          }
                        </ul>
                      </div>
                      <div className="card-footer">
                        <I18n t="main.Mergerequeststatus" />: <b><I18n t={"main."+mergeRequest[key].status} /></b>, <I18n t="main.Mergerequestaccept" />: <b>{mergeRequest[key].accept}</b>
                        {mergeRequest[key].status === "pending" && (<button className="btn btn-danger float-right" onClick={() => this.onRejectMergeRequest(mergeRequest[key]._id)}><I18n t="main.RejectMergerequest" /></button>)}
                        {mergeRequest[key].status === "pending" && (<button className="btn btn-success float-right mr-2" onClick={() => this.onAcceptMergeRequest(mergeRequest[key]._id)}><I18n t="main.AcceptMergerequest" /></button>)}
                      </div>
                    </div>;
                  }
                )
                :
                <Loading />
            }</div>)}

          {this.state.message && (
            <div className="form-group">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}
              </div>
            </div>
          )}
        </div>)}
        <Footer /></>
    );
  }
}
