import axios from 'axios';
import authHeader from './auth-header';

const API_PROPOSER_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/proposer/issue/merge/';

class ProposerIssueMergeService {

  getIssue(issue) {
    return axios.get(API_PROPOSER_URL, {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getIssueCreator(issue) {
    return axios.get(API_PROPOSER_URL + "creator", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  getIssueProposals(issue) {
    return axios.get(API_PROPOSER_URL + "proposals", {
      headers: authHeader(),
      params: {
        issue: issue
      },
    }
    );
  }

  update(userId, issueId, proposalId, listProposals) {
    return axios.post(API_PROPOSER_URL + "update", {
      userId,
      issueId,
      proposalId,
      listProposals
    },
      { headers: authHeader() }
    );
  }

  getMergeRequest(user, issue) {
    return axios.get(API_PROPOSER_URL + "request", {
      headers: authHeader(),
      params: {
        user: user,
        issue: issue
      },
    }
    );
  }

  deleteMergeRequest(mergeRequest) {
    return axios.get(API_PROPOSER_URL + "request/delete", {
      headers: authHeader(),
      params: {
        mergeRequest: mergeRequest
      },
    }
    );
  }

  getMergeRequestProposers(user, issue) {
    return axios.get(API_PROPOSER_URL + "get/merge/request/proposers", {
      headers: authHeader(),
      params: {
        user: user,
        issue: issue
      },
    }
    );
  }

  acceptMergeRequest(mergeRequest) {
    return axios.get(API_PROPOSER_URL + "request/accept", {
      headers: authHeader(),
      params: {
        mergeRequest: mergeRequest
      },
    }
    );
  }

  rejectMergeRequest(mergeRequest) {
    return axios.get(API_PROPOSER_URL + "request/reject", {
      headers: authHeader(),
      params: {
        mergeRequest: mergeRequest
      },
    }
    );
  }

  getMergeRequestHistoryCreatorProposers(user, issue) {
    return axios.get(API_PROPOSER_URL + "get/merge/request/history/proposers", {
      headers: authHeader(),
      params: {
        user: user,
        issue: issue
      },
    }
    );
  }
}

export default new ProposerIssueMergeService();
