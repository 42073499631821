import axios from 'axios';
import authHeader from './auth-header';

const API_PROPOSER_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/proposer/proposal/';

class ProposerProposalCommentService {

  getProposal(proposal) {
    return axios.get(API_PROPOSER_URL, {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalProposer(proposal) {
    return axios.get(API_PROPOSER_URL + "proposer", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalComments(proposal) {
    return axios.get(API_PROPOSER_URL + "comments", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalHistory(proposal) {
    return axios.get(API_PROPOSER_URL + "history", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalSupport(proposal) {
    return axios.get(API_PROPOSER_URL + "support", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

  getProposalStatistics(proposal) {
    return axios.get(API_PROPOSER_URL + "statistics", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );
  }

}

export default new ProposerProposalCommentService();
