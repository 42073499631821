import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import IssueService from "../services/issue.service";
import Footer from './footer';
import Header from './header';

const required = value => {
  if (!value) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thisfieldisrequired" />
      </p>
    );
  }
};

const vtitle = value => {
  if (value.length < 3) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thetitlemustbegreaterthan3characters" />
      </p>
    );
  }
};

export default class IssueCreate extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.handleCreate = this.handleCreate.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);

    this.state = {
      title: "",
      userId: AuthService.getCurrentUser().id,
      successful: false,
      message: ""
    };
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value
    });
  }

  handleCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      IssueService.create(
        this.state.title,
        this.state.userId
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/admin"><I18n t="main.Issues" /></Link>
                } />
              </li>
              <li className="govgr-breadcrumbs__list-item">
                <I18n t="main.Createnewissue" />
              </li>
            </ol>
          </div>
          {!this.state.successful && (
            <>
              <Form
                onSubmit={this.handleCreate}
                ref={c => {
                  this.form = c;
                }}
              >
                <div className="govgr-field">
                  <label className="govgr-label">
                    <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.IssueTitle" /></span>
                    <p className="govgr-hint"><I18n t="main.Settingupanewissue" /></p>
                    <Textarea
                      className="govgr-textarea"
                      rows="5"
                      name="title"
                      value={this.state.title}
                      onChange={this.onChangeTitle}
                      validations={[required, vtitle]}
                    />
                  </label>
                </div>
                <button className="govgr-btn govgr-btn-primary">
                  <I18n t="main.CreateIssue" />
                </button>

                <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </>
          )}

          {this.state.message && (
            <div className="form-group">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}<br />
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-btn govgr-btn-secondary mt-2" to={"/admin"}><I18n t="main.Back" /></Link>
                } />
              </div>
            </div>
          )}
        </div>
        <Footer /></>
    );
  }
}
