import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import I18n from '../I18n';
import AdminIssueEditService from "../services/admin.issue.edit.service";
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

const required = value => {
  if (!value) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thisfieldisrequired" />
      </p>
    );
  }
};

const vtitle = value => {
  if (value.length < 3) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thetitlemustbegreaterthan3characters" />
      </p>
    );
  }
};

const vdescription = value => {
  if (value.length < 3) {
    return (
      <p className="govgr-error-message">
        <I18n t="main.Thedescriptionmustbegreaterthan3characters" />
      </p>
    );
  }
};

export default class AdminIssueEdit extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);

    this.state = {
      title: "",
      oldTitle: "",
      description: "",
      userId: AuthService.getCurrentUser().id,
      issueId: this.props.match.params.issueId,
      issue: null,
      creator: null,
      successful: false,
      message: ""
    };
  }

  componentDidMount() {
    AdminIssueEditService.getIssue(this.state.issueId).then(
      response => {
        this.setState({
          issue: response.data.message,
          title: response.data.message[0].title,
          description: response.data.message[0].description
        });
      },
      error => {
        this.setState({
          issue:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    AdminIssueEditService.getCreator(this.state.issueId).then(
      response => {
        this.setState({
          creator: response.data.message
        });
      },
      error => {
        this.setState({
          creator:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
      oldTitle: e.target.dataset.oldTitle
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    });
  }

  handleUpdate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AdminIssueEditService.update(
        this.state.title,
        this.state.oldTitle,
        this.state.description,
        this.state.userId,
        this.state.issueId
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    const issue = this.state.issue;
    const creator = this.state.creator;
    return (
      <>
        <Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/admin"><I18n t="main.Issues" /></Link>
                } />
              </li>
              <li className="govgr-breadcrumbs__list-item">
                {
                  issue ?
                    Object.keys(issue).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/admin/issue/" + issue[key]._id}>{issue[key].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
            </ol>
          </div>
          {!this.state.successful && (
            <>
              {
                issue ?
                  Object.keys(issue).map(
                    key => {
                      return <div key={key}>
                        <h1 className="govgr-heading-l"><I18n t="main.Edit" />: {issue[key].title}</h1>
                        <Form
                          onSubmit={this.handleUpdate}
                          ref={c => {
                            this.form = c;
                          }}
                        >
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.IssueTitle" /></span>
                              <p className="govgr-hint"><I18n t="main.Changetitleofissue" /></p>
                              {
                                creator ? (<p className="govgr-hint"><I18n t="main.Creator" />: {creator}</p>) : <Loading />
                              }
                              <Textarea
                                className="govgr-textarea"
                                rows="5"
                                name="issue"
                                value={issue[key].title}
                                data-old-title={issue[key].title}
                                onChange={this.onChangeTitle}
                                validations={[required, vtitle]}
                              />
                            </label>
                          </div>
                          <div className="govgr-field">
                            <label className="govgr-label">
                              <span role="heading" aria-level="1" className="govgr-heading-m"><I18n t="main.IssueDescription" /></span>
                              <p className="govgr-hint"><I18n t="main.Changedescriptionofissue" /></p>
                              <Textarea
                                className="govgr-textarea"
                                rows="7"
                                name="description"
                                value={issue[key].description}
                                onChange={this.onChangeDescription}
                                validations={[required, vdescription]}
                              />
                            </label>
                          </div>
                          <div className="govgr-button-group">
                            <button className="govgr-btn govgr-btn-primary">
                              <I18n t="main.SaveIssue" />
                            </button>
                            <Route path={this.props.base} render={() =>
                              <Link key={key} className="govgr-btn govgr-btn-secondary mt-2" to={"/admin/issue/" + issue[key]._id}><I18n t="main." /><I18n t="main.Back" /></Link>
                            } />
                          </div>

                          <CheckButton
                            style={{ display: "none" }}
                            ref={c => {
                              this.checkBtn = c;
                            }}
                          />
                        </Form>
                      </div>;
                    }
                  )
                  :
                  <Loading />
              }
            </>
          )}

          {this.state.message && (
            <div className="form-group">
              <div
                className={
                  this.state.successful
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {this.state.message}<br />
                {
                  issue ?
                    Object.keys(issue).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-btn govgr-btn-secondary mt-2" to={"/admin/issue/" + issue[key]._id}><I18n t="main." /><I18n t="main.Back" /></Link>
                        } />;
                      }
                    )
                    :
                    <Loading />
                }
              </div>
            </div>
          )}
        </div>
        <Footer />
      </>
    );
  }
}
