import axios from 'axios';
import authHeader from './auth-header';

const API_CITIZEN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/citizen/proposal/comment/';

class ProposalCommentVerificationService {

  getComment(comment) {
    return axios.get(API_CITIZEN_URL, {
      headers: authHeader(),
      params: {
        comment: comment
      },
    }
    );
  }

  verified(verifiedType, commentId, userId) {
    return axios.post(API_CITIZEN_URL + "verified", {
      verifiedType,
      commentId,
      userId
    },
      { headers: authHeader() }
    );
  }

  getMyVerificationOnComment(userId, commentId) {
    return axios.get(API_CITIZEN_URL + "find/my/comment/verification", {
      headers: authHeader(),
      params: {
        userId,
        commentId
      },
    }
    );
  }

  verificationDelete(verifiedType, commentId, userId) {
    return axios.post(API_CITIZEN_URL + "verification/delete", {
      verifiedType,
      commentId,
      userId
    },
      { headers: authHeader() }
    );
  }

}

export default new ProposalCommentVerificationService();
