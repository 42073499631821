import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposalCommentVerificationService from "../services/proposal.comment.verification.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';
import RISLEVEL from '../data/risLevel.json';

const trl = {
  '1': 'TRL 1 - Βασικές αρχές παρατηρήθηκαν',
  '2': 'TRL 2 - Διατύπωση της έννοιας της τεχνολογίας',
  '3': 'TRL 3 - Πειραματική απόδειξη της ιδέας',
  '4': 'TRL 4 - Τεχνολογία επικυρωμένη στο εργαστήριο',
  '5': 'TRL 5 - Τεχνολογία επικυρωμένη στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '6': 'TRL 6 - Τεχνολογία που επιδεικνύεται στο σχετικό περιβάλλον (βιομηχανικά σημαντικό περιβάλλον στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής)',
  '7': 'TRL 7 - Επίδειξη πρωτοτύπου συστήματος σε επιχειρησιακό περιβάλλον',
  '8': 'TRL 8 - Το σύστημα ολοκληρώθηκε και πιστοποιήθηκε',
  '9': 'TRL 9 - Πραγματικό σύστημα αποδεδειγμένο σε επιχειρησιακό περιβάλλον (ανταγωνιστική κατασκευή στην περίπτωση βασικών τεχνολογιών γενικής εφαρμογής· ή στο διάστημα)',
};

export default class ProposalCommentVerification extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }
    this.handleVerification = this.handleVerification.bind(this);

    this.state = {
      userId: AuthService.getCurrentUser().id,
      commentId: this.props.match.params.commentId,
      comment: null,
      commentName: null,
      verified: null,
      successful: false,
      message: ""
    };
  }



  componentDidMount() {
    ProposalCommentVerificationService.getComment(this.state.commentId).then(
      response => {
        this.setState({
          comment: response.data.message
        });
      },
      error => {
        this.setState({
          comment:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    ProposalCommentVerificationService.getMyVerificationOnComment(this.state.userId, this.state.commentId).then(
      response => {
        this.setState({
          verified: response.data.message
        });
      },
      error => {
        this.setState({
          verified:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  handleVerification(e, checkedVerification) {
    e.preventDefault();
    // this.setState({
    //   message: "",
    //   successful: false
    // });
    if (checkedVerification) {
      const attributes = checkedVerification.split('_');
      ProposalCommentVerificationService.verified(
        attributes[1],
        attributes[0],
        this.state.userId
      ).then(
        response => {
          // this.setState({
          //   message: response.data.message,
          //   successful: true
          // });
          this.setState({
            verified: attributes[0] + '_' + attributes[1]
          });
        },
        error => {
          // const resMessage =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();
          //
          // this.setState({
          //   successful: false,
          //   message: resMessage
          // });
        }
      );
    } else {
      const attributes = this.state.verified.split('_');
      ProposalCommentVerificationService.verificationDelete(
        attributes[1],
        attributes[0],
        this.state.userId
      ).then(
        response => {
          // this.setState({
          //   message: response.data.message,
          //   successful: true
          // });
          this.setState({
            verified: null
          });
        },
        error => {
          // const resMessage =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();
          //
          // this.setState({
          //   successful: false,
          //   message: resMessage
          // });
        }
      );
    }
  }

  render() {
    const comment = this.state.comment;
    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-breadcrumbs">
            <ol className="govgr-breadcrumbs__list">
              <li className="govgr-breadcrumbs__list-item">
                <Route path={this.props.base} render={() =>
                  <Link className="govgr-breadcrumbs__link" to="/proposer"><I18n t="main.Issues" /></Link>
                } />
              </li>
              <li className="govgr-breadcrumbs__list-item">
                {
                  comment ?
                    Object.keys(comment).map(
                      key => {
                        return <Route key={key} path={this.props.base} render={() =>
                          <Link key={key} className="govgr-breadcrumbs__link" to={"/proposal/" + comment[key].proposal[0]._id}>{comment[key].proposal[0].title}</Link>
                        } />;
                      }
                    )
                    :
                    "Loading..."
                }
              </li>
            </ol>
          </div>
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              {
                comment ?
                  Object.keys(comment).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-xl"><I18n t="main.ProposalTitleandDescription" /></span>
                        <h1 className="govgr-heading-l">{comment[key].proposal[0].title}</h1>
                        {
                          comment[key].proposal[0].amountOfFundingRequested ?
                            <>
                              <dl className="govgr-summary-list">
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.description" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].description}</dd>
                                </div>
                                {!comment[key].proposal[0].newRisLevel && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelOne" /></dt>
                                      <dd className="govgr-summary-list__value">{comment[key].proposal[0].risLevelOne}
                                        {comment[key].proposal[0].risLevelOne !== '' && (<p className="govgr-hint">{RISLEVEL[comment[key].proposal[0].risLevelOne]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelTwo" /></dt>
                                      <dd className="govgr-summary-list__value">{comment[key].proposal[0].risLevelTwo}
                                        {comment[key].proposal[0].risLevelTwo !== '' && (<p className="govgr-hint">{RISLEVEL[comment[key].proposal[0].risLevelTwo]}</p>)}
                                      </dd>
                                    </div>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.risLevelThree" /></dt>
                                      <dd className="govgr-summary-list__value">{comment[key].proposal[0].risLevelThree}
                                        {comment[key].proposal[0].risLevelThree !== '' && (<p className="govgr-hint">{RISLEVEL[comment[key].proposal[0].risLevelThree]}</p>)}
                                      </dd>
                                    </div>
                                  </>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.newRisLevel" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].newRisLevel ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {comment[key].proposal[0].newRisLevel && (
                                  <div className="govgr-summary-list__row">
                                    <dt className="govgr-summary-list__key"><I18n t="main.newRisLevelDescription" /></dt>
                                    <dd className="govgr-summary-list__value">{comment[key].proposal[0].newRisLevelDescription}</dd>
                                  </div>
                                )}
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.amountOfFundingRequested" /></dt>
                                  <dd className="govgr-summary-list__value">{parseInt(comment[key].proposal[0].amountOfFundingRequested).toLocaleString('el-GR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.duration" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].duration}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityStage" /></dt>
                                  <dd className="govgr-summary-list__value">{trl[comment[key].proposal[0].trl]}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.maturityDocumentation" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].maturityStage}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.risDescription" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].risDescription}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerOne" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].searchPartnerOne}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerTwo" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].searchPartnerTwo}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerThree" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].searchPartnerThree}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartnerFour" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].searchPartnerFour}</dd>
                                </div>
                                <div className="govgr-summary-list__row">
                                  <dt className="govgr-summary-list__key"><I18n t="main.searchPartner" /></dt>
                                  <dd className="govgr-summary-list__value">{comment[key].proposal[0].searchPartner ? <i className="bi bi-check h1"></i> : <i className="bi bi-x h1"></i>}</dd>
                                </div>
                                {comment[key].proposal[0].searchPartner && (
                                  <>
                                    <div className="govgr-summary-list__row">
                                      <dt className="govgr-summary-list__key"><I18n t="main.searchPartnersAndProfiles" /></dt>
                                      <dd className="govgr-summary-list__value">{comment[key].proposal[0].searchPartnersAndProfiles}</dd>
                                    </div>
                                  </>
                                )}
                              </dl>
                            </>
                          :
                          ''
                        }
                      </div>;
                    }
                  )
                  :
                  <Loading />
              }
              {
                comment ?
                  Object.keys(comment).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-l"><I18n t="main.Comment" /></span>
                        <h2 className="govgr-heading-l">{comment[key].title}</h2></div>;
                    }
                  )
                  :
                  <Loading />
              }
              <h3><I18n t="main.Verification" /></h3>
              {
                comment ? Object.keys(comment).map(
                  key => {
                    return <div key={key} className="card text-center">
                      <div className="card-body">
                        <ToggleButtonGroup
                          value={this.state.verified}
                          exclusive
                          onChange={this.handleVerification}
                          aria-label={<I18n t="main.Verification" />}
                        >
                          <ToggleButton value={comment[key]._id + "_1"}>
                            <I18n t="main.Verify" />
                          </ToggleButton>
                          <ToggleButton value={comment[key]._id + "_2"}>
                            <I18n t="main.DonotVerify" />
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </div>;
                  }
                ) : <Loading />
              }
            </main>
          </div>
        </div>
        <Footer /></>
    );
  }
}
