import axios from 'axios';
import authHeader from './auth-header';

const API_CITIZEN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/citizen/';

class CitizenSupportProposalService {

  create(userId, proposalId) {
    return axios.post(API_CITIZEN_URL + "proposal/support", {
      userId,
      proposalId
    },
      { headers: authHeader() }
    );
  }

  getProposal(proposal) {
    return axios.get(API_CITIZEN_URL + "support/proposal", {
      headers: authHeader(),
      params: {
        proposal: proposal
      },
    }
    );

  }

}

export default new CitizenSupportProposalService();
