import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Link } from 'react-router-i18n';
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import ProposalService from "../services/proposal.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class BoardMySupports extends Component {
  constructor(props) {
    super(props);
    if(AuthService.getCurrentUser() === null){
      window.location.href = '/';
    }

    this.state = {
      proposals: [],
      userId: AuthService.getCurrentUser().id
    };
  }

  componentDidMount() {
    ProposalService.getProposalsWithMySupports(this.state.userId).then(
      response => {
        this.setState({
          proposals: [...this.state.proposals, response.data.message]
        });
      },
      error => {
        this.setState({
          proposals: [
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
          ]
        });
      }
    );
  }

  render() {
    const items = this.state.proposals[0];
    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              <h1 className="govgr-heading-l"><I18n t="main.ProposalswithmySupports" /></h1>
              {
                items ?
                  Object.keys(items).length > 0 ?
                    Object.keys(items).map(
                      key => {
                        return (
                          <div className="card" style={{ backgroundColor: "#ffffff" }} key={key}>
                            <div className="card-body">
                              <div className="row">
                                <div className="col">
                                  <h5 className="card-title">
                                    <Route path={this.props.base} render={() =>
                                      <Link to={"/proposal/" + items[key][0]._id}>{items[key][0].title}</Link>
                                    } />
                                  </h5>
                                  <p className="govgr-hint"><I18n t="main.Issue" />: {items[key][0].issue[0].title}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )
                    : <ul className="timeline mt-4"><I18n t="main.NoavailableProposalswithmySupports" /></ul>
                  :
                  <Loading />
              }
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
