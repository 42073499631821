import React, { Component } from "react";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import CitizenSupportIssueService from "../services/citizen.support.issue.service";
import Footer from './footer';
import Header from './header';
import Loading from './loading';

export default class CitizenSupportIssue extends Component {
  constructor(props) {
    super(props);
    this.handleCreate = this.handleCreate.bind(this);
    if (!AuthService.getCurrentUser()) {
      this.props.history.push(`/${(this.props.location.pathname.substring(1, 3) === 'ci' ? 'gr' : this.props.location.pathname.substring(1, 3) )}/login`);
    }

    this.state = {
      userId: AuthService.getCurrentUser() ? AuthService.getCurrentUser().id : null,
      issueId: this.props.match.params.issueId,
      issue: null,
      proposerEmail: null
    };
  }

  componentDidMount() {
    CitizenSupportIssueService.getIssue(this.state.issueId).then(
      response => {
        this.setState({
          issue: response.data.message
        });
      },
      error => {
        this.setState({
          issue:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  handleCreate(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      CitizenSupportIssueService.create(
        this.state.userId,
        this.state.issueId
      ).then(
        response => {
          this.setState({
            message: response.data.message,
            successful: true
          });
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            successful: false,
            message: resMessage
          });
        }
      );
    }
  }

  render() {
    const issue = this.state.issue;
    const proposer = issue ? issue[0].user[0].email : null;

    return (
      <><Header />
        <div className="govgr-width-container">
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-third-thirds">
              <h1 className="govgr-heading-l"><I18n t="main.SupportIssue" /></h1>
              {
                issue ?
                  Object.keys(issue).map(
                    key => {
                      return <div key={key}>
                        <span className="govgr-caption-xl"><I18n t="main.IssueTitle" /></span>
                        <h1 className="govgr-heading-l">{issue[key].title}</h1>
                      </div>;
                    }
                  )
                  :
                  (
                    <div className="govgr-warning-text">
                      <span className="govgr-warning-text__icon">!</span>
                      <strong className="govgr-warning-text__text">
                        <span className="govgr-warning-text__assistive">Loading</span>
                        Loading
                      </strong>
                    </div>
                  )
              }
              {
                proposer ? (<p className="govgr-body"><I18n t="main.Creator" />: {proposer}</p>) : <Loading />
              }
              <Form
                onSubmit={this.handleCreate}
                ref={c => {
                  this.form = c;
                }}
              >
                {!this.state.successful && (
                  <div>
                    <div className="row justify-content-end">
                      <div className="col-md-2">
                        <div className="form-group">
                          <button className="btn btn-warning btn-block"><I18n t="main.Support" /></button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.message && (
                  <div className="form-group">
                    <div
                      className={
                        this.state.successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {this.state.message}
                    </div>
                  </div>
                )}
                <CheckButton
                  style={{ display: "none" }}
                  ref={c => {
                    this.checkBtn = c;
                  }}
                />
              </Form>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
