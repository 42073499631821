import axios from 'axios';
import authHeader from './auth-header';

const API_PROPOSER_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/proposer/proposal/';
const API_CITIZEN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/citizen/proposal/';
const API_ADMIN_URL = process.env.REACT_APP_BACKEND_URL + '/api/pods/admin/proposal/';

class ProposalService {

  create(title, issueId, userId) {
    return axios.post(API_PROPOSER_URL + "create", {
      title,
      issueId,
      userId
    },
      { headers: authHeader() }
    );
  }

  createRis3(title, risLevelOne, risLevelTwo, risLevelThree, amountOfFundingRequested, maturityStage, risDescription, searchPartnersAndProfiles, 
    description,
    trl,
    duration,
    newRisLevel,
    newRisLevelDescription,
    searchPartner,
    searchPartnerOne,
    searchPartnerTwo,
    searchPartnerThree,
    searchPartnerFour,
    issueId, userId) {
    return axios.post(API_PROPOSER_URL + "createRis3", {
      title,
      risLevelOne,
      risLevelTwo,
      risLevelThree,
      amountOfFundingRequested,
      maturityStage,
      risDescription,
      searchPartnersAndProfiles,
      description,
      trl,
      duration,
      newRisLevel,
      newRisLevelDescription,
      searchPartner,
      searchPartnerOne,
      searchPartnerTwo,
      searchPartnerThree,
      searchPartnerFour,
      issueId,
      userId
    },
      { headers: authHeader() }
    );
  }

  getProposerProposals(userId) {
    return axios.post(API_PROPOSER_URL + "view/mine", {
      userId
    },
      { headers: authHeader() }
    );
  }

  getProposals() {
    return axios.get(API_CITIZEN_URL + "view/all",
      { headers: authHeader() }
    );
  }

  getProposalsWithMyComments(userId) {
    return axios.post(API_CITIZEN_URL + "my-comments", {
      userId
    },
      { headers: authHeader() }
    );
  }

  getProposalsWithMySupports(userId) {
    return axios.post(API_CITIZEN_URL + "my-supports", {
      userId
    },
      { headers: authHeader() }
    );
  }

  getAdminProposals() {
    return axios.get(API_ADMIN_URL + "view/all",
      { headers: authHeader() }
    );
  }

}

export default new ProposalService();
