import React, { Component } from "react";
import I18n from '../I18n';
import AuthService from "../services/auth.service";
import Footer from './footer';
import Header from './header';

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser()
    };
  }

  render() {
    const { currentUser } = this.state;

    return (
      <>
        <Header />
        <div className="govgr-width-container">
          <div className="govgr-main-wrapper">
            <main className="govgr-grid-column-two-thirds">
              <h2><strong>{currentUser.username}</strong> <I18n t="main.Profile" /></h2>
              <dl className="govgr-summary-list">
                <div className="govgr-summary-list__row">
                  <dt className="govgr-summary-list__key">
                    <I18n t="main.Address" />
                  </dt>
                  <dd className="govgr-summary-list__value">
                    {currentUser.street}, {currentUser.streetNumber}<br />
                    {currentUser.city}, {currentUser.postalCode}
                  </dd>
                </div>
                {/* <div className="govgr-summary-list__row">
                  <dt className="govgr-summary-list__key">
                    <I18n t="main.Occupation" />
                  </dt>
                  <dd className="govgr-summary-list__value">
                    {currentUser.occupation}
                  </dd>
                </div> */}
                <div className="govgr-summary-list__row">
                  <dt className="govgr-summary-list__key">
                    E-mail
                  </dt>
                  <dd className="govgr-summary-list__value">
                    {currentUser.email}
                  </dd>
                </div>
                {
                  (currentUser.afm && currentUser.afm !== null) ? (
                    <>
                    <div className="govgr-summary-list__row">
                      <dt className="govgr-summary-list__key">
                        <I18n t="main.Occupation" />
                      </dt>
                      <dd className="govgr-summary-list__value">
                        <I18n t={"main."+currentUser.occupation}/>
                      </dd>
                    </div>
                    <div className="govgr-summary-list__row">
                      <dt className="govgr-summary-list__key">
                        <I18n t="main.Afm" />
                      </dt>
                      <dd className="govgr-summary-list__value">
                        {currentUser.afm}
                      </dd>
                    </div>
                    <div className="govgr-summary-list__row">
                        <dt className="govgr-summary-list__key">
                          <I18n t="main.JobTitle" />
                        </dt>
                        <dd className="govgr-summary-list__value">
                          {currentUser.jobTitle}
                        </dd>
                    </div>
                    </>
                  ) : ""
                }
                <div className="govgr-summary-list__row">
                  <dt className="govgr-summary-list__key">
                    <I18n t="main.Roles" />
                  </dt>
                  <dd className="govgr-summary-list__value">
                    <ul className="govgr-list govgr-list--spaced">
                      {currentUser.roles &&
                        currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                    </ul>
                  </dd>
                </div>
              </dl>
            </main>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
